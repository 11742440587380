import AssetProcess, { AssetProcessTypes } from '~/models/AssetProcess'
import { type WithURLs } from '~/models/BaseModel'

type TerminationData = {
  personalEmail: string
  terminationDate: string
  terminatePlans: {
    esopPlanId: number
    esopPlanName: string
    terminationSchemaId: number
    terminationSchemaName: string
    reason?: string
    terminateAwards: {
      awardId: number
      awardTransactionType: AssetProcessTypes
      keepCardinality: number
      loseCardinality: number
    }[]
  }[]
}

export type TerminateAward = {
  assetType: string
  displayId: string
  esopSchemaName: string
  id: number
  transactionType: AssetProcessTypes
  keepCardinality: number
  loseCardinality: number
  totalAssetCount: number
  vestedAssetCount: number
}

export default class Termination extends AssetProcess implements WithURLs {
  static label = 'Termination'

  public data!: TerminationData
  public awards!: TerminateAward[]

  public get urlDetail() {
    return `/terminations/${this.id}`
  }

  public get urlEdit() {
    return `/terminations/edit/${this.id}`
  }

  public get urlLabel(): string {
    return this.displayId
  }
}
