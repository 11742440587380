import React from 'react'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import ActivityAssetProcessLink from '~/components/common/ActivityStreamItem/ActivityAssetProcessLink'
import { type ActivityAction } from '~/types/activityActions'

const PaymentConfirmationApproved: ActivityAction = ({ activity }: ActivityStreamItemProps) => {
  if (['BUYBACK'].includes(activity.entity)) {
    return (
      <div>
        Company has confirmed processed payment for {activity.entityLabel} ID{' '}
        <ActivityAssetProcessLink activity={activity} />.
      </div>
    )
  }
  return (
    <div>
      {activity.user?.name ?? 'Company'} has confirmed the receipt of payment for{' '}
      {activity.entityLabel} ID <ActivityAssetProcessLink activity={activity} />.
    </div>
  )
}

export default PaymentConfirmationApproved
