import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type TenantStoreType = {
  tenantUid?: string
  setTenantUid: (tenantUid: string) => void
  clearTenantUid: () => void
}

export const useTenantStore = create<TenantStoreType>()(
  persist(
    (set) => ({
      tenantUid: undefined,
      setTenantUid: (tenantUid: string) => set({ tenantUid }),
      clearTenantUid: () => set({ tenantUid: undefined })
    }),
    {
      name: 'current-tenant'
    }
  )
)
