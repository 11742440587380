import clsx from 'clsx'
import styles from './ProgressIndicator.module.scss'

type Props = {
  progress: number
  className?: string
  showLabel?: boolean
  children?: JSX.Element
}

const ProgressIndicator = ({ progress = 0, showLabel, className, children }: Props) => {
  return (
    <div className={clsx(styles.indicator, className, 'flex-column items-center relative')}>
      {children && <div className="over-fill flex items-center justify-center">{children}</div>}

      <div className="body">
        <svg className="svg" viewBox="0 0 40 40">
          <circle r="15.9154943092" cy="20" cx="20" />
          <circle
            r="15.9154943092"
            cy="20"
            cx="20"
            strokeDashoffset="0"
            strokeDasharray={`${progress} 100`}
            transform="rotate(-90,20,20)"
          />
        </svg>
      </div>
      {showLabel && <div className="fs-12 mt-4">{progress}%</div>}
    </div>
  )
}

export default ProgressIndicator
