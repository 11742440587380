import ActiveLink from '~/components/globals/ActiveLink'
import Link from 'next/link'

import styles from './NavGroup.module.scss'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import SvgIcon from '~/components/common/SvgIcon'
import clsx from 'clsx'

export type NavGroupType = {
  label: string
  link: string
  linkAs?: string
  icon?: string
  target?: string
  isVisible?: boolean
  submenu?: NavGroupType[]
}

export default function NavGroup({
  link,
  icon,
  label,
  submenu,
  linkAs,
  target,
  isVisible = true
}: NavGroupType) {
  const [open, setOpen] = useState(false)
  const router = useRouter()

  useEffect(() => {
    setOpen(!!submenu?.find((item) => router.pathname.startsWith(item.link)))
  }, [router.pathname, submenu])

  const renderSubmenuItem = (item: NavGroupType, i: number) => {
    const submenuItemProps = {
      icon: 'dot',
      ...item
    }
    return <NavGroup key={i} {...submenuItemProps} />
  }

  return isVisible ? (
    <div className={styles.group}>
      {submenu && submenu.length ? (
        <>
          <Link legacyBehavior href={link || submenu[0].link}>
            <a className={clsx(styles.link, { 'submenu-active': open })}>
              {icon && <SvgIcon icon={icon} className="icon mr-12" />}
              <span className="label flex-grow">{label}</span>
              <SvgIcon className="icon ml-12" icon={open ? 'chevron-up' : 'chevron-down'} />
            </a>
          </Link>
          <div style={{ display: open ? 'block' : 'none' }}>
            {submenu && submenu.map((item, i) => renderSubmenuItem(item, i))}
          </div>
        </>
      ) : (
        <ActiveLink href={link} linkAs={linkAs}>
          <a className={styles.link} target={target}>
            {icon && <SvgIcon icon={icon} className="icon mr-12" />}
            <span className="label flex-grow">{label}</span>
          </a>
        </ActiveLink>
      )}
    </div>
  ) : null
}
