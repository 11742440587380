import ExerciseRound from '~/models/ExerciseRound'
import AssetProcess from '~/models/AssetProcess'
import { type WithURLs } from '~/models/BaseModel'

type ExerciseData = {
  roundId: number
  totalExerciseCardinality: number
  totalExercisePrice: number
  exerciseAwards: {
    awardId: number
    exerciseCardinality: number
  }[]
}

export default class Exercise extends AssetProcess implements WithURLs {
  static label = 'Exercise request'

  public static mapping = {
    ...AssetProcess.baseMapping,
    exerciseRound: () => ExerciseRound
  }

  public roundId!: number
  public round!: ExerciseRound

  public data!: ExerciseData

  public get exercisedAssets(): number {
    return this.data.exerciseAwards.reduce((result, award) => result + award.exerciseCardinality, 0)
  }

  public get urlDetail(): string {
    return `/exercises/${this.roundId}/detail/${this.id}`
  }

  public get urlLabel(): string {
    return this.displayId
  }
}
