import styles from './default.module.scss'

export type Props = {
  children?: JSX.Element
}

export default function PlainLayout({ children }: Props) {
  return (
    <>
      <main className={styles.main}>{children}</main>
    </>
  )
}
