import { type Middleware } from '~/types/middlewares'
import { ForbiddenFeatureException } from '~/exceptions/feature'
import { type Feature } from '~/models/Tenant'

const featureMiddleware: Middleware = (context, ...features: Feature[]) => {
  if (features.some((feature) => !context.feature.has(feature))) {
    return {
      error: new ForbiddenFeatureException()
    }
  }
}

export default featureMiddleware
