import styles from './Alert.module.scss'
import type AlertModel from '~/models/Alert'
import clsx from 'clsx'
import SvgIcon from '~/components/common/SvgIcon'

type Props = {
  alert: AlertModel
  className?: string
  textClassName?: string
  onClose: (alert: AlertModel) => void
}

export default function Alert({ alert, onClose, ...props }: Props) {
  return (
    <div className={clsx(styles.alert, props.className, 'type-' + alert.type)}>
      <div className={styles.details}>
        <p className={clsx('text', props.textClassName)}>{alert.text}</p>
      </div>

      <button type="button" className={styles.close} onClick={() => onClose(alert)}>
        <SvgIcon icon="x-close" height={20} />
      </button>
    </div>
  )
}
