import { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { useTenant } from '~/hooks/tenant'
import { useUser } from '~/hooks/user'

const APP_ID = 'r073j86h'

export const useIntercom = () => {
  const [isReady, setIsReady] = useState(false)
  const router = useRouter()
  const { tenant } = useTenant()
  const { user } = useUser()

  // prettier-ignore
  const init = () => {

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-var, prefer-rest-params
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/r073j86h';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
  }

  const boot = (options = {}) => {
    window &&
      window.Intercom &&
      window.Intercom('boot', {
        app_id: APP_ID,
        api_base: 'https://api-iam.intercom.io',
        ...options
      })
  }

  const shutdown = () => {
    window && window.Intercom && window.Intercom('shutdown')
  }

  const update = (options = {}) => {
    window && window.Intercom && window.Intercom('update', options)
  }

  const openWindow = () => {
    window && window.Intercom && window.Intercom('showMessages')
  }

  const options = useMemo(() => {
    if (tenant && user) {
      return {
        name: user.name,
        email: user.email,
        user_id: `${user.id}-${tenant.uid}`,
        user_hash: user.intercomHash,
        user_roles: user.roles.join(','),
        tenant: tenant.uid
      }
    }

    return {}
  }, [user, tenant])

  const mount = () => {
    if (!tenant.isDemo) {
      init()
      boot(options)
      setIsReady(true)
    }
  }

  useEffect(() => {
    if (isReady) {
      update(options)
    }
  }, [isReady, options])

  useEffect(() => {
    const handleRouteChange = () => {
      if (isReady && typeof window !== 'undefined') {
        update()
      }
    }

    router.events.on('routeChangeStart', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [isReady, router.events])

  return {
    mount,
    shutdown,
    openWindow
  }
}
