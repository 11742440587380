import Participant from '~/models/Participant'
import BaseModel from '~/models/BaseModel'
import { getInitials } from '~/utils/helpers'

export const Permissions = [
  'manage_participants',
  'participants',
  'plans',
  'company_shares',
  'manage_company_shares',
  'company_valuations',
  'manage_company_valuations',
  'manage_processes',
  'activity_stream',
  'manage_activity_stream',
  'processes',
  'manage_documents',
  'tenant_settings',
  'documents',
  'manage_payments'
] as const
export type Permission = (typeof Permissions)[number]

export enum Roles {
  admin = 'admin',
  participant = 'participant',
  operator = 'operator',
  approver = 'approver'
}

export default class User extends BaseModel {
  public static mapping = {
    participant: () => Participant
  }

  public id!: number
  public uid!: string
  public name!: string
  public email!: string
  public permissions!: Permission[]
  public roles!: Roles[]
  public participant!: Participant | null

  public activeRoles!: Roles[]

  public intercomHash!: string

  public get initials() {
    return getInitials(this.name)
  }

  public get lang(): string {
    return 'en'
  }

  public get hasParticipant(): boolean {
    return !!this.participant
  }

  public get canSwitchToParticipantView(): boolean {
    return (
      (this.roles.includes(Roles.operator) || this.roles.includes(Roles.approver)) &&
      this.hasParticipant
    )
  }
}
