import React from 'react'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import ActivityAssetProcessLink from '~/components/common/ActivityStreamItem/ActivityAssetProcessLink'
import { type ActivityAction } from '~/types/activityActions'

const Rejected: ActivityAction = ({ activity }: ActivityStreamItemProps) => {
  return (
    <>
      <div>
        {activity.entityLabel} ID
        <span>&nbsp;</span>
        <ActivityAssetProcessLink activity={activity} />
        <span>&nbsp;</span>
        has been rejected by {activity.data?.userFormDetails?.user?.name || 'unknown user'}.
      </div>
      <div className="pt-4">{'Reason for rejection: ' + activity.data.userFormDetails.reason}</div>
    </>
  )
}

export default Rejected
