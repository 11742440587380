import { type ReactNode } from 'react'
import styles from './Card.module.scss'
import clsx from 'clsx'

type Props = {
  title?: string | JSX.Element
  description?: string
  actions?: string | JSX.Element
  border?: boolean
  shadow?: boolean
  noPadding?: boolean
  header?: ReactNode | JSX.Element
  children?: ReactNode | JSX.Element
  className?: string
  headerClassName?: string
  contentClassName?: string
  headerTag?: 'h3' | 'h4'
}

export default function Card(props: Props) {
  const classNames = [styles.card]
  props.className && classNames.push(props.className)

  const HeaderTag = props.headerTag || 'h4'
  const headerClassName = ['header']
  props.headerClassName && headerClassName.push(props.headerClassName)

  props.border && classNames.push('is-border')
  props.noPadding && classNames.push('is-no-padding')
  props.shadow && classNames.push('has-shadow')

  const hasHeader = props.title || props.description

  return (
    <div className={clsx(classNames)}>
      {hasHeader && (
        <header className={clsx(headerClassName)}>
          <div className="flex items-center">
            <div className="flex-grow">
              {props.title && (
                <HeaderTag className="title">
                  <span className="skeleton-header">{props.title}</span>
                </HeaderTag>
              )}
              {props.description && (
                <div className="description fs-12 color-grey mt-4">{props.description}</div>
              )}
            </div>
            {props.actions && <div>{props.actions}</div>}
          </div>
        </header>
      )}

      {props.header}

      <div className={clsx('content', props.contentClassName)}>{props.children}</div>
    </div>
  )
}
