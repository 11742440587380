import BaseModel, { type WithURLs } from '~/models/BaseModel'
import { getInitials } from '~/utils/helpers'
import { type CustomFieldType } from '~/models/CustomField'

export const Types = ['employee', 'freelancer', 'company'] as const
export type Type = (typeof Types)[number]

export default class Participant extends BaseModel implements WithURLs {
  public id!: number
  public firstname!: string | null
  public lastname!: string | null
  public middlename!: string | null
  public participantType!: Type
  public entityName!: string | null
  public addressLine1!: string | null
  public addressLine2!: string | null
  public addressCity!: string | null
  public addressZip!: string | null
  public addressCountry!: string | null
  public birthDate!: string | null
  public customFields!: CustomFieldType | null
  public addressAdditional!: string | null
  public bankNumber!: string | null
  public iban!: string | null
  public swift!: string | null

  public email!: string
  public emailSecondary!: string | null
  public employeeNumber!: string | null
  public entityId!: string | null
  public entityIdentificationText!: string | null
  public hireDate!: string | null
  public jurisdiction!: string | null
  public tenantId!: number | null
  public terminationDate!: string | null
  public userId!: number | null
  public agreementDate!: string | null
  public agreementSpecification!: string | null

  public createdAt!: string | null
  public deletedAt!: string | null
  public updatedAt!: string | null

  public fullName!: string | null
  public fullAddress!: string | null
  public name!: string

  public totalAssetCount!: number | null
  public vestedAssetCount!: number | null
  public vestedAssetValue!: number | null
  public assetTypes!: string | null
  public isTerminated!: boolean | null
  public flags!: string | null

  public hrisUid!: string | null
  public hrisLastSyncedAt!: string | null

  public hasProcess: boolean | undefined

  public get initials() {
    return getInitials(this.name || '')
  }

  public get urlDetail() {
    return `/people/${this.id}/profile`
  }

  public get urlEdit() {
    return `/people/${this.id}/profile/edit`
  }

  public get urlLabel(): string {
    return this.name
  }
}
