import Link from 'next/link'
import React, { useMemo } from 'react'
import BigNumber from '~/components/common/BigNumber'
import ProgressBar from '~/components/common/ProgressBar'
import Button from '~/components/globals/Button'
import { useModals } from '~/hooks/modals'
import CompanyPool from '~/models/CompanyPool'
import Card from '../globals/Card'
import { useFormatters } from '~/hooks/formatters'
import TooltipWrap from '~/components/globals/TooltipWrap'
import { useTenant } from '~/hooks/tenant'
import { getColorNameByIndex } from '~/utils/colors'
import SvgIcon from '~/components/common/SvgIcon'
import clsx from 'clsx'
import { useAcl } from '~/hooks/user'

type PlansOverviewProps = {
  className?: string
}

export default function PlansOverview({ className }: PlansOverviewProps) {
  const { esopPlans } = useTenant()
  const { formatCompact, formatNormalNumber } = useFormatters()
  const acl = useAcl()

  const companyPools = useMemo(() => {
    const pools: CompanyPool[] = []
    esopPlans.forEach((plan) => {
      let pool = pools.find((p) => p.id === plan.companyPool.id)
      if (!pool) {
        pool = new CompanyPool({
          ...plan.companyPool,
          awardedAssetCount: 0,
          lockedAssetCount: 0,
          availableAssetCount: plan.availableAssetCount
        })
        pools.push(pool)
      }
      pool.awardedAssetCount += plan.awardedAssetCount
      pool.lockedAssetCount += plan.lockedAssetCount
    })
    pools.sort((p, q) => p.name.localeCompare(q.name))

    return pools
  }, [esopPlans])

  const modals = useModals()
  const newAward = () => {
    modals.open(import('~/modals/awards/NewAward'))
  }

  return (
    <Card className={clsx(className, 'mb-32')}>
      <div className="flex items-center">
        <h4 className="flex-grow">
          <div className="fs-18 fw-500">Plans overview</div>
        </h4>
        {acl.isManager && (
          <Button className="btn btn-light" size="sm" onClick={newAward}>
            New award
          </Button>
        )}
      </div>
      <div className="mt-24 flex justify-between">
        <BigNumber number={esopPlans.length} label={esopPlans.length === 1 ? 'Plan' : 'Plans'} />
        <div />
        <div />
        <div />
        <BigNumber
          number={companyPools.reduce((r, p) => r + p.totalAssetCount, 0)}
          label="All assets"
          compactFormat={true}
        />
        <BigNumber
          number={
            Math.floor(
              (100 * companyPools.reduce((r, p) => r + p.availableAssetCount, 0)) /
                (companyPools.reduce((r, p) => r + p.totalAssetCount, 0) || 1)
            ) + '%'
          }
          label="Available"
        />
      </div>
      <div className="mt-32 fs-18 fw-500">Pool capacity</div>
      {companyPools.map((companyPool, i) => (
        <React.Fragment key={companyPool.id}>
          <div className="flex justify-between fs-12 mt-16">
            <div>{companyPool.name}</div>
            <TooltipWrap
              title={
                formatNormalNumber(companyPool.usedAssetCount) +
                ' / ' +
                formatNormalNumber(companyPool.totalAssetCount)
              }
            >
              <div>
                {formatCompact(companyPool.usedAssetCount) +
                  ' / ' +
                  formatCompact(companyPool.totalAssetCount)}
              </div>
            </TooltipWrap>
          </div>
          <ProgressBar
            value={companyPool.usage}
            showPercentage={false}
            showPercentageOnValue={true}
            color={getColorNameByIndex(i)}
            height={11}
            className="mt-4 mb-16"
          />
        </React.Fragment>
      ))}
      <Link href="/plans">
        <Button variant="text" size="xs">
          <>
            See the detail
            <SvgIcon icon="chevron-right" height={14} className="ml-4" />
          </>
        </Button>
      </Link>
    </Card>
  )
}
