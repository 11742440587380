import React from 'react'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import ActivityAssetProcessLink from '~/components/common/ActivityStreamItem/ActivityAssetProcessLink'
import { type ActivityAction } from '~/types/activityActions'

const PaymentConfirmation: ActivityAction = ({ activity }: ActivityStreamItemProps) => {
  return (
    <div>
      {activity.entityLabel} ID <ActivityAssetProcessLink activity={activity} /> is pending payment
      confirmation.
    </div>
  )
}

PaymentConfirmation.icon = 'clock-fill'

export default PaymentConfirmation
