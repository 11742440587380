import styles from './LineLoader.module.scss'
import clsx from 'clsx'

type Props = {
  absolute?: boolean
  className?: string
}

export default function LineLoader(props: Props) {
  return (
    <div
      className={clsx(styles.loader, 'skeleton-hide', props.className, {
        'is-absolute': props.absolute
      })}
    />
  )
}
