import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { useAlertsStore } from '~/stores/alerts'
import Alert from '../common/Alert'
import styles from './Alerts.module.scss'

export default function Alerts() {
  const alertsStore = useAlertsStore()

  if (!alertsStore.list.length) {
    return <></>
  }

  return (
    <TransitionGroup className={styles.alerts}>
      {alertsStore.list.map((item) => (
        <CSSTransition
          addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
          key={item.id}
          classNames="fade"
        >
          <Alert alert={item} onClose={alertsStore.remove} className="mt-4" />
        </CSSTransition>
      ))}
    </TransitionGroup>
  )
}
