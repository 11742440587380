import { type AxiosError } from 'axios'

export class APIException extends Error {
  public isAPI: boolean
  public isHandled: boolean
  public status: number
  public data: any

  constructor(message: string, status?: number, data?: any) {
    if (typeof status === 'undefined') {
      status = -1
    }

    if (status >= 500) {
      message = 'Unknown error occurred, please try again later.'
    } else if (data && data.error) {
      message = data.error.message
    } else if (Array.isArray(data?.errors)) {
      message = 'Validation failed. Please verify your parameters.'
    }

    super(message)

    this.isAPI = true
    this.isHandled = true
    this.status = status
    this.data = data

    Object.setPrototypeOf(this, new.target.prototype)
  }

  public get isValidationError(): boolean {
    return Array.isArray(this.data?.errors)
  }

  public get validationErrors(): any[] {
    return this.data?.errors ?? []
  }

  public get statusCode(): number {
    return this.status
  }

  public get code(): string | undefined {
    return this.data?.error?.code
  }

  toJSON(): Record<string, any> {
    return {
      message: this.message,
      status: this.status,
      code: this.code,
      data: this.data
    }
  }

  static fromJSON(json: any): APIException {
    return new APIException(json.message, json.status, json.data)
  }

  static fromAxiosError(error: AxiosError): APIException {
    const { message, response } = error

    return new APIException(message, response?.status, response?.data)
  }
}
