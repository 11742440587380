import { useEffect, useMemo, useState } from 'react'
import { useTenant } from '~/hooks/tenant'
import { useUser } from '~/hooks/user'
import { useMount } from 'react-use'

const API_KEY = '120849e7-5b39-4795-4f68-b58b27ca6af3'

declare const window: Window &
  typeof globalThis & {
    pendo: any
  }

export const usePendo = () => {
  const [isReady, setIsReady] = useState(false)
  const { tenant } = useTenant()
  const { user } = useUser()

  // prettier-ignore
  const init = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-var, prefer-rest-params
    (function(apiKey){(function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w){(function(m){o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);}y=e.createElement(n);y.async=!0;y.src='https://cdn.eu.pendo.io/agent/static/'+apiKey+'/pendo.js';z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');})(API_KEY);
  }

  const update = (options = {}) => {
    window && window.pendo && window.pendo.updateOptions(options)
  }

  const options = useMemo(() => {
    if (tenant && user) {
      return {
        visitor: {
          id: `${user.id}-${tenant.uid}`,
          full_name: user.name,
          email: user.email
        },
        account: {
          id: tenant.uid,
          name: tenant.tenantEntity?.name
        }
      }
    }

    return {}
  }, [user, tenant])

  const mount = () => {
    if (tenant.isDemo) {
      init()
      window && window.pendo && window.pendo.initialize(options)
      setIsReady(true)
    }
  }

  useEffect(() => {
    if (isReady) {
      update(options)
    }
  }, [isReady, options])

  useMount(mount)

  return {
    mount
  }
}
