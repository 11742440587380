import { useState } from 'react'
import { type Ref } from '~/types/app'

export const ref = <T>(value: T): Ref<T> => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [state, setState] = useState<T>(value)

  const stateObj = Object.defineProperty({} as Ref<T>, 'value', {
    get() {
      return state
    },
    set(value: T) {
      setState(value)
    }
  })

  return stateObj
}
