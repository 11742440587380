class LoggerService {
  public child() {
    return new LoggerService()
  }

  log(...data: any[]) {
    console.log(...data)
  }

  debug(...data: any[]) {
    console.log(...data)
  }
}

const logger = new LoggerService()

export default logger
