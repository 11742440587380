import Badge from '~/components/globals/Badge'
import { useTenant } from '~/hooks/tenant'
import { useMemo } from 'react'
import tenantService from '~/services/TenantService'

export default function ActiveTenant() {
  const { tenant } = useTenant()

  const isSwitched = useMemo<boolean>(
    () => !!tenant.uid && tenant.uid !== tenantService.uid,
    [tenant.uid]
  )

  return isSwitched ? (
    <Badge color="grey-100" textColor="grey-dark">
      <>
        {tenant.uid} : {tenant.tenantEntity.name}
      </>
    </Badge>
  ) : null
}
