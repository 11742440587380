import React from 'react'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import ActivityAssetProcessLink from '~/components/common/ActivityStreamItem/ActivityAssetProcessLink'
import { type ActivityAction } from '~/types/activityActions'

const DocumentRejectedByParticipant: ActivityAction = ({ activity }: ActivityStreamItemProps) => {
  return (
    <>
      <div>
        Signer has declined to sign the {activity.entityLabel} ID
        <span>&nbsp;</span>
        <ActivityAssetProcessLink activity={activity} />
        {activity.documentType ? ' ' + activity.documentType : ''}.
      </div>
      <div className="pt-4">{activity.data.signer.name + ', ' + activity.data.signer.email}</div>
      <div className="pt-4">{'Reason: ' + (activity.data.signer.reason ?? '')}</div>
    </>
  )
}

export default DocumentRejectedByParticipant
