import config from './ConfigService'
class TenantService {
  public uid = ''

  public detect() {
    let tenant = ''

    if (config.TENANT_STRATEGY === 'subdomain') {
      const hostname = typeof location !== 'undefined' ? location.hostname : ''
      tenant = hostname.split('.').shift() ?? ''
    }

    if (!tenant) {
      tenant = config.DEFAULT_TENANT
    }

    this.uid = tenant
  }
}

const tenant = new TenantService()
tenant.detect()
export default tenant
