import BaseModel from './BaseModel'

export default class CompanyPool extends BaseModel {
  public id!: number
  public assetCardinality!: number
  public assetShareCardinality!: number
  public assetType!: string
  public createdAt!: string
  public issuedAt!: string
  public name!: string
  public esopPlansCount!: number
  public assetsCount!: number
  public isPhantom?: boolean
  public isDefault?: boolean

  public availableAssetCount!: number
  public awardedAssetCount!: number
  public lockedAssetCount!: number

  public get totalAssetCount() {
    return this.awardedAssetCount + this.lockedAssetCount + this.availableAssetCount
  }

  public get usedAssetCount() {
    return this.totalAssetCount - this.availableAssetCount
  }

  public get usage() {
    return Math.floor(
      (100 * (this.awardedAssetCount + this.lockedAssetCount)) / this.totalAssetCount
    )
  }

  public get assetRatio() {
    return this.assetCardinality / this.assetShareCardinality
  }

  public get isDeletable() {
    return this.esopPlansCount === 0
  }
}
