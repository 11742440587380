import React from 'react'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import ActivityAssetProcessLink from '~/components/common/ActivityStreamItem/ActivityAssetProcessLink'
import { type ActivityAction } from '~/types/activityActions'

const PartiallyVested: ActivityAction = ({ activity }: ActivityStreamItemProps) => {
  return (
    <div>
      {activity.data.vestedAssetCount ?? 0} asset{activity.data.vestedAssetCount === 1 ? '' : 's'}{' '}
      vested for the {activity.entityLabel} ID
      <span>&nbsp;</span>
      <ActivityAssetProcessLink activity={activity} />.
    </div>
  )
}

PartiallyVested.hasDetail = false

export default PartiallyVested
