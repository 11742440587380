export const colorsHex = {
  'primary': '#FF8039',
  'blue': '#2970FF',
  'violet-light': '#9D85F9',
  'primary-light': '#FFA978',
  'blue-light': '#6D9EFF',
  'violet': '#5B33F5',
  'green': '#B6DE16',
  'secondary-light': '#707A85'
}
export const colorsNamesInOrder = Object.keys(colorsHex)

export function getColorByIndex(index: number) {
  const colorsName = getColorNameByIndex(index)
  return colorsName && colorsHex[colorsName as keyof typeof colorsHex] !== undefined
    ? colorsHex[colorsName as keyof typeof colorsHex]
    : colorsHex.primary
}
export function getColorNameByIndex(index: number) {
  return colorsNamesInOrder[index]
}
