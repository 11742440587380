import { type Page } from '~/types/pages'
import { useAcl } from '~/hooks/user'
import DashboardParticipant from '~/components/dashboard/DashboardParticipant'
import DashboardOperator from '~/components/dashboard/DashboardOperator'
import { Roles } from '~/models/User'

const DashboardPage: Page = ({}) => {
  const user = useAcl()

  const RenderDashboard = () => {
    if (user.isTenantEntityPerson) {
      return <DashboardOperator />
    } else if (user.has(Roles.participant)) {
      return <DashboardParticipant />
    }
    return <></>
  }

  return (
    <div className="container py-32 px-24">
      <RenderDashboard />
    </div>
  )
}

DashboardPage.layout = 'dashboard'
DashboardPage.middleware = 'auth'
DashboardPage.title = 'Dashboard'

export default DashboardPage
