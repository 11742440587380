import BaseModel from '~/models/BaseModel'
import { type EsopSchemaDocumentRecipientType } from '~/models/EsopSchema'

export type RecipientStatus =
  | 'WAITING'
  | 'PENDING'
  | 'COMPLETED'
  | 'DECLINED'
  | 'EXPIRED'
  | 'VOIDED'

export type RecipientType = {
  type: EsopSchemaDocumentRecipientType
  name: string
  email: string
  status: RecipientStatus
  reason?: string
  sentAt?: string
  signedAt?: string
  declinedAt?: string
}

export type StepDocumentType = {
  documentName: string
  recipients: RecipientType[]
}

export default class AssetProcessStep extends BaseModel {
  public title!: string
  public subtitle?: string
  public timestamp?: string
  public isActive?: boolean
  public success?: boolean

  public documents?: StepDocumentType[]
}
