import Card from '../globals/Card'
import styles from './CompanyValuationGraph.module.scss'
import Button from '~/components/globals/Button'
import TooltipCard from '~/components/graphs/TooltipCard'
import LineGraph from '~/components/graphs/LineGraph'
import { useValuation } from '~/hooks/valuation'
import React, { useMemo } from 'react'
import { colorsHex } from '~/utils/colors'
import { DateTime } from 'ts-luxon'
import Link from 'next/link'
import { useFormatters } from '~/hooks/formatters'
import TooltipWrap from '~/components/globals/TooltipWrap'
import clsx from 'clsx'
import SvgIcon from '~/components/common/SvgIcon'

type CompanyValuationProps = {
  className?: string
}

export default function CompanyValuation({ className }: CompanyValuationProps) {
  const { companyValuationGraphData, currentCompanyValuation, publicValuation } = useValuation()
  const { currencySymbol, formatCompact, formatAmount } = useFormatters()
  const { labelView, datasets, currentDate } = companyValuationGraphData

  const previousCompanyValuation: number = useMemo(
    () => publicValuation?.getValuation(undefined, -1) || 0,
    [publicValuation]
  )

  const valuationChange: number = useMemo(
    () =>
      previousCompanyValuation > 0 && currentCompanyValuation > 0
        ? Math.round(
            ((currentCompanyValuation - previousCompanyValuation) / previousCompanyValuation) * 100
          )
        : 0,
    [currentCompanyValuation, previousCompanyValuation]
  )

  return (
    <Card noPadding className={clsx(styles.company_valuation, className)}>
      <div className="card-header flex items-center px-24 pt-24 pb-0">
        <h4 className="card-title flex-grow">Company valuation</h4>
      </div>
      <div
        className={clsx(styles.company_valuation_number, 'card-body flex items-center px-32 mt-16')}
      >
        <div className={clsx(styles.company_valuation_number_currency, 'color-grey-500')}>
          {currencySymbol}
        </div>
        <TooltipWrap title={formatAmount(currentCompanyValuation)}>
          <div className={styles.company_valuation_number_value}>
            {formatCompact(currentCompanyValuation)}
          </div>
        </TooltipWrap>
        {valuationChange !== 0 && (
          <span className={clsx('fs-12', valuationChange > 0 ? 'color-green-dark' : 'color-grey')}>
            <SvgIcon
              icon={valuationChange > 0 ? 'arrow-narrow-up' : 'arrow-narrow-down'}
              height={10}
            />
            {valuationChange > 0 && '+'}
            {valuationChange}%
          </span>
        )}
      </div>
      <div className="graph">
        {datasets.length > 0 && (
          <LineGraph
            tooltipComponent={TooltipCard}
            labels={labelView}
            datasets={datasets}
            type="compact"
            height="150px"
            chartOptions={{
              spanGaps: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  type: 'time',
                  time: {
                    unit: 'month'
                  },
                  border: {
                    display: false
                  }
                },
                y: {
                  border: {
                    display: false
                  }
                }
              }
            }}
            annotation={{
              line1: {
                type: 'line',
                borderColor: colorsHex.primary,
                borderWidth: 1,
                borderDash: [6, 5],
                scaleID: 'x',
                value: DateTime.fromMillis(currentDate).toISO()
              }
            }}
          />
        )}
      </div>
      <div className="card-footer pt-10 pb-24 px-24">
        <Link href="/company/valuations">
          <Button variant="text" size="xs">
            <>
              See the detail <SvgIcon icon="chevron-right" height={14} className="ml-4" />
            </>
          </Button>
        </Link>
      </div>
    </Card>
  )
}
