import styles from './BigNumber.module.scss'
import React from 'react'
import { useFormatters } from '~/hooks/formatters'
import TooltipWrap from '~/components/globals/TooltipWrap'
import clsx from 'clsx'

type BigNumberProps = {
  number: number | string
  label: string | React.ReactNode
  prefixText?: string
  suffixText?: string
  className?: string
  compactFormat?: boolean
}

const TooltipValue = (props: BigNumberProps) => {
  const { formatNormalNumber, formatCompact } = useFormatters()

  let tooltip: string | null = null
  let formattedValue = props.number
  if (typeof props.number === 'number') {
    if (props.compactFormat) {
      formattedValue = formatCompact(props.number)
      tooltip = formatNormalNumber(props.number).toString()
    } else {
      formattedValue = formatNormalNumber(props.number)
    }
  }

  const valueContent = (
    <div className={clsx('fw-700 color-grey-700', styles.value, 'skeleton-big-number-value')}>
      {formattedValue}
    </div>
  )

  return tooltip ? <TooltipWrap title={tooltip}>{valueContent}</TooltipWrap> : valueContent
}

export default function BigNumber(props: BigNumberProps) {
  const { label, prefixText, suffixText, className } = props

  return (
    <div className={clsx('fs-14', styles.big_number, className)}>
      <div className={clsx('flex items-center', styles.main)}>
        {prefixText && (
          <div className="mr-8 color-grey">
            {React.isValidElement(prefixText) ? (
              <>{prefixText}</>
            ) : (
              <span className="skeleton-text">{prefixText}</span>
            )}
          </div>
        )}
        <TooltipValue {...props} />

        {suffixText && (
          <div className="ml-8 color-grey">
            {React.isValidElement(suffixText) ? (
              suffixText
            ) : (
              <span className="skeleton-text">{suffixText}</span>
            )}
          </div>
        )}
      </div>
      <div className="mt-4 fs-12 color-grey">
        {typeof label === 'string' ? <span className="skeleton-text">{label}</span> : label}
      </div>
    </div>
  )
}
