import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { useMount } from 'react-use'
import { useFormatters } from '~/hooks/formatters'
import { useTenant } from '~/hooks/tenant'
import DividendPayout from '~/models/AssetProcess/DividendPayout'
import DividendRound from '~/models/DividendRound'
import ValueBox from '../../common/ValueBox'
import Button from '../../globals/Button'
import Card from '../../globals/Card'
import SvgIcon from '~/components/common/SvgIcon'

type Props = {
  className?: string
}

const ParticipantRounds = ({ className }: Props) => {
  const { tenantApi } = useTenant()
  const router = useRouter()
  const { formatDate, formatAmount } = useFormatters()
  const [dividendRounds, setDividendRounds] = useState<DividendRound[]>([])

  useMount(async () => {
    const rounds: DividendRound[] = (
      await tenantApi.$get('rounds', {
        params: {
          roundType: 'dividendPayout',
          withProcesses: true
        }
      })
    ).map((r: object) => new DividendRound(r))

    setDividendRounds(rounds)
  })

  const onPayoutRequest = async (roundId: number) => {
    const res = await tenantApi.$post(`asset/dividend-payouts/${roundId}/dividend-payout`, {
      roundId
    })
    const payout = new DividendPayout(res)
    router.push(payout.urlDetail)
  }

  return (
    <>
      {dividendRounds.map((round) => (
        <Card
          key={round.id}
          title={round.name}
          description={'Deadline ' + formatDate(round.participantDeadline)}
          className={className}
          actions={
            !round.process ? (
              <Button size="sm" onClick={() => onPayoutRequest(round.id)}>
                {DividendPayout.label}
              </Button>
            ) : undefined
          }
        >
          <div className="row">
            <div className="col col-auto">
              <ValueBox
                value={round.process?.processAssetCount ?? round.eligibleAssetCardinality ?? 0}
                label="Vested assets"
              />
            </div>
            <div className="col col-auto">
              <ValueBox
                value={formatAmount(
                  (round.process?.processAssetCount ?? round.eligibleAssetCardinality ?? 0) *
                    round.data.payoutPerAsset
                )}
                label="Dividend payout"
              />
            </div>
          </div>

          {round.instruction && (
            <div className="fs-12 my-12" style={{ whiteSpace: 'pre-line' }}>
              {round.instruction}
            </div>
          )}

          {round.process && (
            <Button variant="text" size="xs" to={round.process.urlDetail} className="mt-16">
              See the detail <SvgIcon icon="chevron-right" height={14} className="ml-4" />
            </Button>
          )}
        </Card>
      ))}
    </>
  )
}

export default ParticipantRounds
