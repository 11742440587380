import { type Middleware } from '~/types/middlewares'
import { originTenantApi } from '~/services/APIService'

const authMiddleware: Middleware = (context) => {
  if (!context.isLoggedIn) {
    return {
      redirect: originTenantApi.getUrl('login')
    }
  }
}

export default authMiddleware
