import { type ReactNode } from 'react'

type Props = {
  error: Error
  children?: ReactNode
}

export default function ErrorState({ error, children }: Props) {
  return (
    <div className="fullscreen flex items-center">
      <div className="container py-32 text-center">
        <h1>Oops</h1>
        <p className="pt-16">{error.message}</p>

        {children}
      </div>
    </div>
  )
}
