import React from 'react'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import ActivityAssetProcessLink from '~/components/common/ActivityStreamItem/ActivityAssetProcessLink'
import { useFormatters } from '~/hooks/formatters'
import type Activity from '~/models/Activity'
import Exercise from '~/models/AssetProcess/Exercise'
import { type ActivityAction } from '~/types/activityActions'

const ExercisedByRequest: ActivityAction = ({ activity }: ActivityStreamItemProps) => {
  const { formatNormalNumber } = useFormatters()

  return (
    <div>
      {activity.entityLabel} ID
      <span>&nbsp;</span>
      <ActivityAssetProcessLink activity={activity} />:{' '}
      {formatNormalNumber(activity.data.exerciseProcess.exercisedCardinality)} assets exercised by
      Exercise ID{' '}
      <ActivityAssetProcessLink
        activity={
          {
            entityModel: Exercise,
            assetProcess: activity.data.exerciseProcess
          } as unknown as Activity
        }
        className="fw-700"
      />
      .
    </div>
  )
}

export default ExercisedByRequest
