import React from 'react'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import ActivityAssetProcessLink from '~/components/common/ActivityStreamItem/ActivityAssetProcessLink'
import { useFormatters } from '~/hooks/formatters'
import { type ActivityAction } from '~/types/activityActions'
import type Activity from '~/models/Activity'
import Termination from '~/models/AssetProcess/Termination'

const Terminated: ActivityAction = ({ activity }: ActivityStreamItemProps) => {
  const { formatNormalNumber } = useFormatters()
  return (
    <>
      <div>
        {activity.entityLabel} ID
        <span>&nbsp;</span>
        <ActivityAssetProcessLink activity={activity} />:{' '}
        {formatNormalNumber(activity.data.terminationProcess.terminatedCardinality)} assets returned
        to pool as a result of Termination ID{' '}
        <ActivityAssetProcessLink
          activity={
            {
              entityModel: Termination,
              assetProcess: activity.data.terminationProcess
            } as unknown as Activity
          }
          className="fw-700"
        />
        .
      </div>
      <div className="pt-4">
        Total count decreased from{' '}
        {formatNormalNumber(
          activity.data.terminationProcess.terminatedCardinality +
            activity.data.terminationProcess.currentCardinality
        )}{' '}
        to {formatNormalNumber(activity.data.terminationProcess.currentCardinality)}.
      </div>
    </>
  )
}

export default Terminated
