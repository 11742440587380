import { type BadgeStatusColors } from '~/components/common/BadgeStatus'
import BaseModel from '~/models/BaseModel'
import { type EsopSchemaData } from './AssetProcess'
import { CustomFieldType } from './CustomField'

function getStatusColor(status: string): BadgeStatusColors {
  switch (status) {
    case 'draft':
      return 'outline-grey'
    case 'closed':
      return 'grey'
    case 'pending':
      return 'orange'
    case 'open':
      return 'violet'
    default:
      return 'default'
  }
}

export default abstract class Round extends BaseModel {
  abstract typeLabel: string
  abstract status: 'draft' | 'open' | 'closed' | 'pending'
  abstract urlDetail: string
  abstract urlEdit: string
  abstract urlOverview: string

  public id!: number
  public name!: string
  public description!: string
  public instruction!: string
  public state!: 'draft' | 'published'
  public createdAt!: string

  public esopPlanId!: number
  public esopSchemaId!: number
  public esopSchemaName!: string
  public esopSchema!: EsopSchemaData

  public acceptedPersonCount!: number
  public totalPersonCount!: number

  public customFields!: CustomFieldType | null

  public get isDraft() {
    return this.status === 'draft'
  }

  public get isOpen() {
    return this.status === 'open'
  }

  public get isPending() {
    return this.status === 'pending'
  }

  public get statusColor(): BadgeStatusColors {
    return getStatusColor(this.status)
  }

  public get urlLabel(): string {
    return this.name
  }
}
