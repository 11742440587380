import BaseModel from '~/models/BaseModel'
import { getInitials } from '~/utils/helpers'

export default class TenantEntity extends BaseModel {
  public address!: string
  public currencyCode!: string
  public identificationNumber!: string
  public jurisdiction!: string
  public name!: string
  public registeredIn!: string
  public swift!: string
  public website!: string
  public contactEmail!: string
  public countryOfOperation!: string
  public stateOfOperation?: string | null
  public iban!: string

  public get initials() {
    return getInitials(this.name)
  }
}
