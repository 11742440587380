import { create } from 'zustand'
import { combine } from 'zustand/middleware'
import {
  type OnboardingStepStates,
  type OnboardingStepTypes,
  type OnboardingSteps
} from '~/hooks/wizard'

export const useWizardStore = create(
  combine(
    {
      steps: {} as OnboardingSteps,
      showWizardBanner: false,
      areStepsUpdated: false
    },
    (set, get) => ({
      setSteps(steps: OnboardingSteps) {
        set({ steps, areStepsUpdated: false })
      },
      updateStep(step: OnboardingStepTypes, state: OnboardingStepStates) {
        const steps = get().steps
        let showWizardBanner = get().showWizardBanner
        let areStepsUpdated = false
        if (Object.hasOwn(steps, step) && steps[step] !== state) {
          steps[step] = state
          showWizardBanner = true
          areStepsUpdated = true
        }
        set({ steps, showWizardBanner, areStepsUpdated })
      },
      setShowBanner(showWizardBanner: boolean) {
        set({ showWizardBanner })
      }
    })
  )
)
