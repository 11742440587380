import React from 'react'
import { DateTime } from 'ts-luxon'
import UnknownHeader from '~/components/common/ActivityStreamItem/ActivityStreamItemHeader/Unknown'
import UnknownDetail from '~/components/common/ActivityStreamItem/ActivityStreamItemDetail/Unknown'
import TimelineItem from '~/components/common/TimelineItem'
import { useFormatters } from '~/hooks/formatters'
import type ActivityModel from '~/models/Activity'
import { type ActivityAction } from '~/types/activityActions'
import * as detailComponents from './ActivityStreamItem/ActivityStreamItemDetail'
import * as headerComponents from './ActivityStreamItem/ActivityStreamItemHeader'

type SkeletonableActivityStreamItemProps = {
  activity?: ActivityModel
}

export type ActivityStreamItemProps = {
  activity: ActivityModel
}

export default function ActivityStreamItem({ activity }: SkeletonableActivityStreamItemProps) {
  const { formatDate } = useFormatters()

  const action = activity?.action as keyof typeof headerComponents
  const HeaderComponent: ActivityAction = headerComponents[action] ?? UnknownHeader

  const entity = activity?.entity as keyof typeof detailComponents
  const DetailComponent = detailComponents[entity] ?? UnknownDetail

  return (
    <TimelineItem
      item={{
        icon: HeaderComponent.icon ?? 'check',
        header: activity ? <HeaderComponent activity={activity} /> : <>Unknown activity</>,
        detail:
          HeaderComponent.hasDetail !== false ? (
            activity ? (
              <DetailComponent activity={activity} />
            ) : (
              <UnknownDetail />
            )
          ) : undefined,
        date: formatDate(
          activity ? activity.createdAt : DateTime.now() ?? '',
          DateTime.DATETIME_MED
        ),
        document: activity?.document ?? undefined
      }}
    />
  )
}
