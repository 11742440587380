let modalId = 1

export type ModalOptions = {
  nested?: boolean
}

export default class Modal {
  public id: string
  public component: any
  public options: ModalOptions
  public params: any

  public promise?: Promise<any>
  public resolve?: (value: unknown) => void

  constructor(component: any, options: ModalOptions = {}, params: any = {}) {
    this.id = String(modalId++)
    this.component = component
    this.options = options
    this.params = params
  }
}
