import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { useMount } from 'react-use'
import { ref } from './app'
import { type IFilters } from './filters'
import { getPathname, lazyAsync } from '~/utils/helpers'

type ListOptions<T> = {
  fetch: (list: any) => Promise<T[] | Record<string, any> | void>
  model?: { new (...args: any[]): T }
  filters?: IFilters

  offset?: boolean
  defaultSortBy?: string
  defaultPerPage?: number
  defaultSortDesc?: boolean
  autoUpdateRoute?: boolean
  autoRestoreRoute?: boolean
}

export const useList = <T extends Record<string, any>>(options: ListOptions<T>) => {
  const router = useRouter()

  options = Object.assign(
    {
      defaultSortBy: 'id',
      defaultSortDesc: true,
      defaultPerPage: 25,
      autoUpdateRoute: true,
      autoRestoreRoute: true
    },
    options
  )

  // let isUpdatingRoute = false
  let isPendingUpdate = false
  const [isUpdatingTable, setUpdateTable] = useState(false)
  let isRestoringRoute = isUpdatingTable
  const isLoading = ref(false)
  const isInitialLoading = ref(true)
  const rows = ref<T[]>([])
  const total = ref(0)
  const page = ref(1)
  const perPage = ref(options.defaultPerPage!)
  const sortBy = ref(options.defaultSortBy!)
  const sortDesc = ref(options.defaultSortDesc!)

  const isAnyLoading = isInitialLoading.value || isLoading.value

  const fetch = async () => {
    isLoading.value = true
    try {
      const result = await options.fetch({
        getQuery
      })

      if (Array.isArray(result)) {
        if (options.offset) {
          rows.value = rows.value.concat(
            result.map((item) => (options.model ? new options.model(item) : item))
          )
        } else {
          rows.value = result.map((item) => (options.model ? new options.model(item) : item))
        }
      } else if (result) {
        if (options.offset) {
          rows.value = rows.value.concat(
            result.data.map((item: any) => {
              return options.model ? new options.model(item) : item
            })
          )
        } else {
          rows.value = result.data.map((item: any) => {
            return options.model ? new options.model(item) : item
          })
        }
        total.value = result.meta.total
      }
    } catch (err) {
      // TODO: handle alerts
      // $alerts.error(err as Error)
    }

    isLoading.value = false
    isInitialLoading.value = false
  }

  const getQuery = (isApi = false) => {
    const query: Record<string, any> = {}

    if (options.filters) {
      Object.assign(query, options.filters.getQuery(isApi))
    }

    if (sortBy.value !== options.defaultSortBy || isApi) {
      query.sortBy = sortBy.value
    }
    if (sortDesc.value !== options.defaultSortDesc || isApi) {
      query.sortDesc = sortDesc.value
    }
    if (perPage.value !== options.defaultPerPage || isApi) {
      const perPageName = isApi && options.offset ? 'limit' : 'perPage'
      query[perPageName] = perPage.value
    }

    if (isApi && options.offset) {
      query.offset = (page.value - 1) * perPage.value
    } else {
      page.value !== 1 && (query.page = page.value)
    }
    return query
  }

  const restoreFromQuery = (query: Record<string, any> = router.query) => {
    isRestoringRoute = true

    if (options.filters) {
      options.filters.restoreFromQuery(query)
    }

    if (query.page) {
      page.value = Number(query.page) || 1
    }
    if (query.perPage) {
      perPage.value = Number(query.perPage) || options.defaultPerPage!
    }
    if (query.sortBy) {
      sortBy.value = String(query.sortBy) || options.defaultSortBy!
    }
    if (query.sortDesc) {
      sortDesc.value = query.sortDesc === 'true' || query.sortDesc === '1'
    }
  }

  const collectSelected = (selected: any[]) => {
    return selected.map((value) => rows.value.find((item) => item.id === value)!)
  }

  const updateRoute = () => {
    // isUpdatingRoute = true

    router.replace({
      pathname: getPathname(router.asPath),
      query: getQuery()
    })

    // setTimeout(() => {
    //   isUpdatingRoute = false
    // }, 50)
  }

  const update = async () => {
    if (isLoading.value) {
      isPendingUpdate = true

      return
    }

    options.autoUpdateRoute && updateRoute()

    await fetch()

    if (isPendingUpdate) {
      isPendingUpdate = false
      await update()
    }
  }

  const scheduleReload = () => {
    if (!isInitialLoading.value) {
      rows.value = []
      page.value = 1
      setUpdateTable(true)
    }
  }

  const scheduleUpdate = lazyAsync(update, 200, useRef(Math.random()).current)

  useMount(() => {
    // has to be the first hook
    if (options.autoRestoreRoute) {
      restoreFromQuery()
      // watch(route, (value) => !isUpdatingRoute && restoreFromQuery(value.query))
    }

    setUpdateTable(true)
  })

  if (options.filters) {
    options.filters.watch(() => {
      if (!isRestoringRoute) {
        page.value = 1
        setUpdateTable(true)
      }
    })
  }

  const [lastPage, setLastPage] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)

  useEffect(() => {
    if (perPage.value * page.value >= total.value) {
      setLastPage(true)
    } else {
      setLastPage(false)
    }

    setIsEmpty(total.value <= 0)
  }, [perPage.value, page.value, total.value])

  useEffect(() => {
    if (!isRestoringRoute) {
      setUpdateTable(true)
    }
  }, [page.value])

  useEffect(() => {
    if (!isRestoringRoute) {
      page.value = 1
      setUpdateTable(true)
    }
  }, [perPage.value, sortBy.value, sortDesc.value])

  // Only place where to fire scheduleUpdate()
  useEffect(() => {
    if (isUpdatingTable) {
      setUpdateTable(false)
      scheduleUpdate()
    }
  }, [isUpdatingTable])

  return {
    rows,
    total,
    page,
    perPage,
    sortBy,
    sortDesc,
    isLoading,
    isInitialLoading,
    isAnyLoading,
    lastPage,
    isEmpty,
    collectSelected,
    getQuery,
    fetch,
    update,
    scheduleReload
  }
}

export type ListType = ReturnType<typeof useList>
