import React from 'react'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import ActivityAssetProcessLink from '~/components/common/ActivityStreamItem/ActivityAssetProcessLink'
import { type ActivityAction } from '~/types/activityActions'

const DocumentExpiredForCompany: ActivityAction = ({ activity }: ActivityStreamItemProps) => {
  return (
    <>
      <div>
        {activity.entityLabel} ID
        <span>&nbsp;</span>
        <ActivityAssetProcessLink activity={activity} />
        <span>&nbsp;</span>
        {activity.documentType ? activity.documentType + ' ' : ''}has has not been signed before the
        expiration date.
      </div>
      <div className="pt-4">{activity.data.signer.name + ', ' + activity.data.signer.email}</div>
    </>
  )
}

export default DocumentExpiredForCompany
