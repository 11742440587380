import Alert, { type AlertOptions } from '~/models/Alert'
import { useAlertsStore } from '~/stores/alerts'

export const useAlerts = () => {
  const alertsStore = useAlertsStore()

  const open = (options: AlertOptions) => {
    const alert = new Alert(options)

    alertsStore.add(alert)

    if (alert.timeout) {
      alert.timer = setTimeout(() => {
        alert.timer = undefined
        close(alert)
      }, alert.timeout)
    }

    return alert
  }

  const close = (alert: Alert | string) => {
    alertsStore.remove(alert)

    return true
  }

  const error = (error: Error) => {
    return open({
      type: 'error',
      text: error.message
    })
  }

  return {
    open,
    close,
    error
  }
}
