import React from 'react'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import { type ActivityAction } from '~/types/activityActions'

const Unknown: ActivityAction = ({ activity }: ActivityStreamItemProps) => {
  return <>{`Unknown activity (${activity.activityStreamType})`}</>
}

Unknown.icon = 'error'
Unknown.hasDetail = false

export default Unknown
