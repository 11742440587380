import clsx from 'clsx'
import styles from './WizardBanner.module.scss'
import Button from '~/components/globals/Button'
import ProgressIndicator from '~/components/common/ProgressIndicator'
import SvgIcon from '~/components/common/SvgIcon'
import { useWizard } from '~/hooks/wizard'
import { useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { sleep } from '~/utils/helpers'

export default function WizardBanner() {
  const {
    countOfSteps,
    countOfFinishedSteps,
    showWizardBanner,
    wizardProgress,
    isWizardComplete,
    setShowBanner
  } = useWizard()
  const [progress, setProgress] = useState(wizardProgress)
  const [stepsFinished, setStepsFinished] = useState(countOfFinishedSteps)
  const [isAnimated, setIsAnimated] = useState(false)

  const updateBanner = () => {
    setProgress(wizardProgress)
    setStepsFinished(countOfFinishedSteps)
  }

  const onBannerEntered = async () => {
    await sleep(500)
    updateBanner()
    setIsAnimated(true)
  }

  useEffect(() => {
    isAnimated && updateBanner()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardProgress])

  return (
    <CSSTransition
      addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
      in={showWizardBanner}
      classNames="slide-down"
      onEntered={onBannerEntered}
      onExited={() => setIsAnimated(false)}
      unmountOnExit
    >
      <div className={clsx(styles.wizard, 'px-16 mx-16 mb-16 py-20 fs-14 color-grey-50')}>
        <div className="flex justify-between">
          <ProgressIndicator progress={progress} className={styles.indicator}>
            <span className="fw-500">
              {stepsFinished}/{countOfSteps}
            </span>
          </ProgressIndicator>

          <Button
            size="xs"
            variant="icon"
            className="color-grey-500 color-grey-300@hover"
            onClick={() => setShowBanner(false)}
            icon="x-close"
          />
        </div>

        <div className="fw-600 mt-16">Setup wizard</div>

        <p className="color-grey-200 mt-4">
          {isWizardComplete ? 'Complete' : 'Almost there. Continue setting up your account.'}
        </p>

        {!isWizardComplete && (
          <Button
            variant="text"
            suffix={<SvgIcon icon="arrow-right" height={16} className="color-primary ml-8 arrow" />}
            className={clsx(styles.btn, 'color-grey-50 mt-16')}
            to="/dashboard"
          >
            Go back to setup wizard
          </Button>
        )}
      </div>
    </CSSTransition>
  )
}
