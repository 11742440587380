import { DateTime } from 'ts-luxon'
import DividendPayout from './AssetProcess/DividendPayout'
import { type WithURLs } from './BaseModel'
import Round from './Round'

export default class DividendRound extends Round implements WithURLs {
  static label = 'Dividend payout round'
  public get typeLabel() {
    return 'Dividend payout'
  }

  public static mapping = {
    processes: () => DividendPayout
  }

  public eligibleAssetCardinality!: number
  public participantDeadline!: string
  public processes?: DividendPayout[]

  public data!: {
    payoutPerAsset: number
    payoutPerShare: number
    totalCompanyProfit: number
    totalShareCount: number
    year: number
  }

  public get hasProcess() {
    return !!this.processes?.length
  }

  public get process() {
    return this.processes?.[0]
  }

  public get status() {
    if (this.state === 'draft') {
      return 'draft'
    }
    const currentDate = DateTime.now().toISODate() ?? ''
    return currentDate <= this.participantDeadline ? 'open' : 'closed'
  }

  public get urlDetail() {
    return `/dividends/${this.id}`
  }

  public get urlEdit() {
    return `/dividends/edit/${this.id}`
  }

  public get urlOverview() {
    return `/dividends`
  }

  public get totalPayout() {
    return this.data.payoutPerAsset * this.eligibleAssetCardinality
  }
}
