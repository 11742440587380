import BaseModel from '~/models/BaseModel'
import type Tier from '~/models/Tier'
import { DateTime } from 'ts-luxon'

export type PaymentFrequencyType = 'annually' | 'monthly'

export default class CurrentTier extends BaseModel {
  declare id: number

  declare additionalEnvelopePrice: number
  declare additionalSeatPrice: number
  declare envelopesUsage: number
  declare seatsUsage: number

  declare annualPrice: number
  declare billingDay: number

  declare envelopes: number
  declare seats: number

  declare firstBillingDate: string // date

  declare monthlyPrice: number
  declare paymentFrequency: PaymentFrequencyType

  declare tierId: number

  declare validFrom: string // date
  declare validTo: string // date

  public get nextBillingDate(): DateTime {
    let billingDay = DateTime.now().set({ days: this.billingDay })

    if (DateTime.now().day >= this.billingDay) {
      billingDay = billingDay.plus({ months: 1 })
    }

    return billingDay
  }

  public getExportToTier(): Partial<Tier> {
    return {
      monthlyPrice: this.monthlyPrice,
      annualPrice: this.annualPrice,
      envelopes: this.envelopes,
      seats: this.seats,
      additionalSeatPrice: this.additionalSeatPrice,
      additionalEnvelopePrice: this.additionalEnvelopePrice
    }
  }
}
