import { useFormatters } from '~/hooks/formatters'
import styles from './BadgeStatus.module.scss'
import clsx from 'clsx'

export type BadgeStatusColors =
  | 'default'
  | 'grey'
  | 'grey-dark'
  | 'blue'
  | 'red'
  | 'violet'
  | 'violet-light'
  | 'orange'
  | 'outline-grey'
  | 'black'

type Props = {
  status?: string | React.ReactNode
  color?: BadgeStatusColors
  className?: string
}

export default function BadgeStatus({ status, color = 'default', className }: Props) {
  const { codeToLabel } = useFormatters()
  return (
    <div className={clsx(styles.status, 'bg-' + color, className, 'skeleton-status')}>
      {typeof status === 'string' ? codeToLabel(status ?? '') : status}
    </div>
  )
}
