import React, { type SVGProps, useMemo } from 'react'
import dynamic from 'next/dynamic'
import styles from './SvgIcon.module.scss'
import clsx from 'clsx'

export type SvgIconProps = {
  icon: string
  folder?: string
  className?: string
  width?: number
  height?: number
  noStrokeColor?: boolean
}
export default function SvgIcon({
  icon,
  width,
  height,
  className,
  noStrokeColor,
  folder = 'icons'
}: SvgIconProps) {
  const SvgLoader = useMemo(
    () => dynamic<SVGProps<SVGSVGElement>>(() => import(`~/assets/${folder}/${icon}.svg`)),
    [icon, folder]
  )

  return (
    <SvgLoader
      width={width}
      height={height}
      className={clsx(styles.svg, !noStrokeColor ? styles.strokeCurrentColor : '', className)}
    />
  )
}
