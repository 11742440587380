import { useTenant } from '~/hooks/tenant'
import Avatar from '../globals/Avatar'
import Dropdown, { type IDropdownOption } from '../globals/Dropdown'
import styles from './NavBar.module.scss'
import Notifications from '~/components/layout/Notifications'
import { useStore } from '~/stores'
import { useUser } from '~/hooks/user'
import { useIntercom } from '~/hooks/intercom'
import ActiveTenant from '~/components/layout/navBar/ActiveTenant'
import { originTenantApi } from '~/services/APIService'
import { useRouter } from 'next/router'
import clsx from 'clsx'
import { Roles } from '~/models/User'

export default function NavBar() {
  const store = useStore()
  const { tenant } = useTenant()
  const { user, activeView } = useUser()
  const intercom = useIntercom()
  const router = useRouter()

  const logout = async () => {
    intercom.shutdown()
    location.href = originTenantApi.getUrl('logout')
  }

  const myProfile = async () => {
    await router.push(`/profile`)
  }

  const accountOptions: IDropdownOption[] = []

  if (user.canSwitchToParticipantView) {
    accountOptions.unshift({
      label:
        activeView() === Roles.operator || activeView() === Roles.approver
          ? 'Switch to participant'
          : user.roles.includes(Roles.operator)
          ? 'Switch to operator'
          : 'Switch to approver',
      link: '/login/switch',
      icon: 'switch-horizontal-01'
    })
  }

  if (user?.participant) {
    accountOptions.push({
      label: 'My profile',
      action: myProfile,
      icon: 'user-circle'
    })
  }

  if (!tenant.isDemo) {
    accountOptions.push({
      label: 'Logout',
      action: logout,
      icon: 'log-out-01'
    })
  }

  return (
    <div className={styles.navbar}>
      <div className={clsx(styles.inner, 'container flex items-center px-24')}>
        <button type="button" className={styles.menuToggle} onClick={() => store.toggleSidebar()}>
          <span className={styles.line}></span>
          <span className={styles.line}></span>
          <span className={styles.line}></span>
        </button>

        <ActiveTenant />

        <div className="flex-grow"></div>
        <div className="flex-auto flex items-center">
          {/*<Button icon="search" size="sm" className="color-grey-500"></Button>*/}
          <Notifications />
          {user && (
            <Dropdown
              activator={({ toggle }) => (
                <button className={styles.user} onClick={toggle}>
                  <Avatar user={user || undefined} />
                </button>
              )}
              options={accountOptions}
              className="ml-10"
            />
          )}
        </div>
      </div>
    </div>
  )
}
