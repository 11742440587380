import AssetProcess from '~/models/AssetProcess'
import type Participant from '~/models/Participant'
import { useModals } from '~/hooks/modals'
import { useTenant } from '~/hooks/tenant'
import { useState } from 'react'
import type Round from '~/models/Round'

type OnSuccessFnReturnType = () => Promise<void> | void

export type EligibleRecord = {
  id: number
  name: string
  reasons?: any[]
  accepted?: boolean
}
export type EligibleAssetCountMap = Record<string, number>
export type EligibleParticipantRequestMap = Record<string, number>

type EligibilityResponse = {
  eligible: EligibleRecord[]
  notEligible?: EligibleRecord[]
  eligibleAssetCountMap: EligibleAssetCountMap
  participantRoundRequestMap: EligibleParticipantRequestMap
  roundAccepted: EligibleRecord[]
}

export const useEligibility = () => {
  const { tenantApi } = useTenant()
  const [eligibles, setEligibles] = useState<EligibleRecord[]>([])
  const [eligibleAssetCountMap, setEligibleAssetCountMap] = useState<EligibleAssetCountMap>({})
  const [participantRoundRequestMap, setParticipantRoundRequestMap] =
    useState<EligibleParticipantRequestMap>({})

  const modals = useModals()
  const fetchEligibility = async (round: Round, date: string, includeAccepted = false) => {
    const data = await tenantApi.$post<EligibilityResponse>(`/asset/processes/eligibility`, {
      esopSchemaId: round.esopSchemaId,
      // participantIds: Array.isArray(participantId) ? participantId : [participantId],
      date,
      onlyEligible: true,
      roundId: round.id
    })

    if (includeAccepted && data.roundAccepted?.length) {
      data.eligible = data.eligible.concat(
        data.roundAccepted.map((p) => ({ ...p, accepted: true }))
      )
    }

    const sortedEligibles = data.eligible.sort((a: EligibleRecord, b: EligibleRecord) =>
      a.name.localeCompare(b.name)
    )
    setEligibles(sortedEligibles)
    setEligibleAssetCountMap(data.eligibleAssetCountMap)
    setParticipantRoundRequestMap(data.participantRoundRequestMap)

    return {
      eligibles: sortedEligibles,
      ...data
    }
  }

  const getRunningProcesses = async (participantId: number): Promise<AssetProcess[]> => {
    const data = await tenantApi.$get('asset/processes', {
      params: {
        participantId,
        isFinished: false
      }
    })

    return data.map((process: object) => new AssetProcess(process))
  }

  const openRunningProcesses = async (processes: AssetProcess[], participant: Participant) => {
    if (processes.length) {
      await modals.open(
        import('~/modals/common/RunningProcesses'),
        {},
        {
          processes,
          participant
        }
      )
    }
  }

  const checkRunningProcesses = async (participant: Participant, fn: OnSuccessFnReturnType) => {
    const processes = await getRunningProcesses(participant.id)

    if (processes.length) {
      await openRunningProcesses(processes, participant)
    } else {
      await fn()
    }
  }

  return {
    fetchEligibility,
    checkRunningProcesses,
    getRunningProcesses,
    openRunningProcesses,
    eligibles,
    eligibleAssetCountMap,
    participantRoundRequestMap
  }
}

export type Eligibility = ReturnType<typeof useEligibility>
