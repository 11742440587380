import styles from './Avatar.module.scss'
import clsx from 'clsx'

type ModelInitials = {
  initials: string
}

type Props = {
  user?: ModelInitials
  size?: number | 'fill'
  className?: string
  text?: string
  image?: string | null
}

export default function Avatar({ image, user, size = 'fill', className, text }: Props) {
  const style = {
    width: size === 'fill' ? '100%' : size / 16 + 'rem',
    fontSize: size <= 24 ? '10px' : '17px'
  }

  return (
    <>
      {image ? (
        <img alt="" src={image} />
      ) : (
        <span className={clsx(styles.avatar, className)} style={style}>
          {user ? user.initials : text}
        </span>
      )}
    </>
  )
}
