import BaseModel from '../BaseModel'
import ValuationPeriodModel from '~/models/Company/ValuationPeriodModel'
import { DateTime } from 'ts-luxon'
import { findLast, findLastIndex } from 'lodash'

export default class ValuationModel extends BaseModel {
  public static mapping = {
    periods: () => ValuationPeriodModel
  }

  public id!: number
  public name!: string
  public createdAt!: string
  public updatedAt!: string
  public deletedAt!: string
  public isPrimary!: boolean
  public isPublic!: boolean
  public isParticipantVisible!: boolean
  public periods!: ValuationPeriodModel[]

  private isSorted = false

  public get currentPeriod() {
    return findLast(this.sortedPeriods, (period) => {
      return period.validFromDateTime.toMillis() <= DateTime.now().startOf('day').toMillis()
    })
  }

  public get currentValuation(): number {
    return this.getValuation() || 0
  }

  public getValuation(_date?: DateTime, position = 0): number | undefined {
    if (!this.sortedPeriods.length) {
      return undefined
    }

    const date = (_date || DateTime.now()).startOf('day')

    const index = findLastIndex(this.sortedPeriods, (period) => {
      return period.validFromDateTime.startOf('day').toMillis() <= date.toMillis()
    })

    const valIndex = index + position

    if (valIndex < 0 || valIndex > this.sortedPeriods.length) {
      return undefined
    }

    return this.sortedPeriods[valIndex].valuation
  }

  public get sortedPeriods(): ValuationPeriodModel[] {
    if (!this.periods.length) {
      return []
    }

    if (!this.isSorted) {
      this.periods.sort((a, b) => {
        return a.validFromDateTime.toMillis() - b.validFromDateTime.toMillis()
      })

      this.isSorted = true
    }

    return this.periods
  }
}
