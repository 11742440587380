import { create } from 'zustand'
import { combine } from 'zustand/middleware'
import Alert from '~/models/Alert'

export const useAlertsStore = create(
  combine(
    {
      list: [] as Alert[]
    },
    (set, get) => ({
      add(alert: Alert) {
        const list = get().list.slice(0)
        list.push(alert)

        set({ list })
      },
      remove(alert: Alert | string) {
        const list = get().list.slice(0)

        let index = -1

        if (alert instanceof Alert) {
          index = list.indexOf(alert)
        } else {
          index = list.findIndex((item) => item.id === alert)
        }

        list.splice(index, 1)

        set({ list })
      }
    })
  )
)
