import Link from 'next/link'
import React from 'react'
import AwardsBeingVested from '~/components/awards/AwardsBeingVested'
import ExerciseRoundOverview from '~/components/exercises/ExerciseRoundOverview'
import Button from '~/components/globals/Button'
import ActivityStream from '~/components/common/ActivityStream'
import CompanyValuation from '~/components/templates/CompanyValuation'
import PortfolioGraph from '~/components/templates/PortfolioGraph'
import { useUser } from '~/hooks/user'
import ParticipantDividendRounds from '../dividends/dashboard/ParticipantRounds'
import SvgIcon from '~/components/common/SvgIcon'
import CustomGuide from '~/components/dashboard/CustomGuide'
import { useTenant } from '~/hooks/tenant'

export default function DashboardParticipant() {
  const { user } = useUser()
  const { tenant } = useTenant()

  return (
    <>
      <div className="row">
        <div className="col col-4@xl">
          <PortfolioGraph
            type="compact"
            participantId={user?.participant?.id || 0}
            label="My portfolio"
            className="mb-32"
          />
          {tenant.uid !== 'graphaware' ? <CompanyValuation className="mt-32" /> : null}
          <CustomGuide className="mt-32" />
        </div>
        <div className="col col-8@xl">
          <AwardsBeingVested
            participantId={user?.participant?.id || 0}
            className="mb-32"
            link={
              <Link href="/awards">
                <Button variant="text" size="xs">
                  <>
                    See all awards <SvgIcon icon="chevron-right" height={14} className="ml-4" />
                  </>
                </Button>
              </Link>
            }
          />
          <ExerciseRoundOverview className="mb-32" dashboard={true} />
          <ParticipantDividendRounds className="mb-32" />

          <ActivityStream
            headerClassName="pb-16"
            defaultPerPage={5}
            link={
              <Link href="/activity-stream">
                <Button variant="text" size="xs">
                  <>
                    See all activities <SvgIcon icon="chevron-right" height={14} className="ml-4" />
                  </>
                </Button>
              </Link>
            }
          />
        </div>
      </div>
    </>
  )
}
