import React from 'react'
import Circle from '~/components/globals/Circle'
import { getColorNameByIndex } from '~/utils/colors'
import type { ITooltip } from './LineGraph'
import styles from './TooltipCard.module.scss'
import clsx from 'clsx'

export default function TooltipCard(props: ITooltip) {
  const chart = props.chart
  const chartArea = chart.chartArea

  const style: Record<string, string | number> = {
    opacity: props.opacity
  }

  const mx = (chartArea.left + chartArea.right) / 2
  if (props.x < mx) {
    style.left = props.x + 15
  } else {
    style.right = chart.width - props.x + 15
  }

  const my = (chartArea.top + chartArea.bottom) / 2
  if (props.y < my) {
    style.top = props.y + 15
  } else {
    style.bottom = chart.height - props.y + 15
  }

  const items = props.items.map((item, i: number) => ({
    value: item.value,
    label: item.label,
    footerComponent: item.footerComponent,
    color: getColorNameByIndex(i)
  }))

  return (
    <div className={clsx('tooltip bg-white fs-12', styles.container)} style={style}>
      <div className="label pt-8 px-10 border-bottom-grey-100">{props.label}</div>
      <div className="items py-8 px-10">
        {items
          .filter((item) => !!item.value || item.value === 0)
          .sort((a, b) => {
            let result = b.value - a.value
            if (result === 0) {
              result = a.label.localeCompare(b.label)
            }
            return result
          })
          .map((item, i) => {
            return (
              <div key={i}>
                <div className="flex justify-start items-center">
                  <Circle color={item.color} size="small" className="mr-4" />
                  <div>{item.label}:</div>
                  &nbsp;
                  <div className="fw-700">{props.valueFormatter(item.value)}</div>
                </div>
                {item.footerComponent}
              </div>
            )
          })}
      </div>
    </div>
  )
}
