import React from 'react'
import BadgeAssetType from '~/components/common/BadgeAssetType'

type Props = {
  assetTypes?: string[]
}

export function AssetTypes({ assetTypes }: Props) {
  return assetTypes ? (
    <>
      {assetTypes.map((assetType, i) => (
        <BadgeAssetType key={i} assetType={assetType} className="mr-8" />
      ))}
    </>
  ) : (
    <>-</>
  )
}
