import React from 'react'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import ActivityAssetProcessLink from '~/components/common/ActivityStreamItem/ActivityAssetProcessLink'
import { type ActivityAction } from '~/types/activityActions'

const PaymentConfirmationRejected: ActivityAction = ({ activity }: ActivityStreamItemProps) => {
  return (
    <>
      <div>
        {activity.entityLabel} ID <ActivityAssetProcessLink activity={activity} />
        has been archived by {activity.assetProcess?.archivistUser?.name ?? ''}.
      </div>
      <div className="pt-4">Reason: Payment was not received within the given deadline</div>
    </>
  )
}

export default PaymentConfirmationRejected
