import clsx from 'clsx'
import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'
import { DateTime } from 'ts-luxon'
import SvgIcon from '~/components/common/SvgIcon'
import Button from '~/components/globals/Button'
import Card from '~/components/globals/Card'
import { useFormatters } from '~/hooks/formatters'
import { useRounds } from '~/hooks/rounds'
import ExerciseRound from '~/models/ExerciseRound'
import styles from './ExerciseRounds.module.scss'

type Props = {
  className?: string
  dashboard?: boolean
}

export default function ExerciseRoundOverview({ className, dashboard }: Props) {
  const router = useRouter()
  const { formatDate } = useFormatters()
  const [expanded, setExpanded] = useState<Record<string, boolean | undefined>>({})
  const currentDate: string = DateTime.now().toISODate() ?? ''
  const { rounds } = useRounds('exercise', ExerciseRound)

  const exerciseRounds = useMemo(() => {
    return rounds.filter(
      (round: ExerciseRound) =>
        DateTime.fromISO(round.participantDeadline).toISODate() ?? '' >= currentDate
    )
  }, [rounds])

  const onAction = (round: ExerciseRound) => {
    if (dashboard) {
      router.push('/exercises')
    } else {
      setExpanded((expanded) => ({ ...expanded, [round.id]: expanded[round.id] === false }))
    }
  }

  const getDescription = (round: ExerciseRound) => {
    return round.exerciseStart > currentDate
      ? `Round opens on ${formatDate(round.exerciseStart)}`
      : `Deadline ${formatDate(round.participantDeadline)}`
  }

  return (
    <>
      {exerciseRounds.map((round) => (
        <Card
          key={round.id}
          title={round.name}
          description={getDescription(round)}
          actions={
            round.isOpen ? (
              <Button
                size={dashboard ? 'sm' : 'default'}
                onClick={() => {
                  router.push(`/exercises/${round.id}/detail/edit/new`)
                }}
              >
                New exercise request
              </Button>
            ) : undefined
          }
          className={className}
          headerClassName="pb-16"
        >
          <div
            className={clsx(
              styles.instruction,
              (dashboard || expanded[round.id] === false) && styles.instruction_part,
              'fs-12'
            )}
          >
            {round.instruction}
          </div>
          <div className="pt-24">
            <Button variant="text" size="xs" onClick={() => onAction(round)}>
              <>
                {dashboard ? (
                  <>
                    See the detail <SvgIcon icon="chevron-right" height={14} className="ml-4" />
                  </>
                ) : expanded[round.id] !== false ? (
                  'Read less'
                ) : (
                  'Read more'
                )}
              </>
            </Button>
          </div>
        </Card>
      ))}
    </>
  )
}
