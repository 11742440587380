import CompanyPoolModel from '~/models/CompanyPool'
import BaseModel from './BaseModel'
import { DocumentTemplateTypes } from './DocumentTemplate'

export type PlanDocumentTemplate = {
  id: number
  documentTemplateId: number
  documentTemplate: {
    id: number
    documentTemplateType: DocumentTemplateTypes
    name: string
    extension: string
  }
}

export default class EsopPlan extends BaseModel {
  public id!: number
  public companyPoolId!: number
  public name!: string
  public validFrom!: string | null
  public exitDefinitionPercentage!: number | null

  public assetType!: string
  public createdAt!: string
  public updatedAt!: string

  public companyPool!: CompanyPoolModel

  public availableAssetCount!: number
  public awardedAssetCount!: number
  public lockedAssetCount!: number
  public esopSchemaCount!: number

  public isCustom!: boolean

  public static mapping = {
    companyPool: () => CompanyPoolModel
  }

  public get totalAssetCount() {
    return this.awardedAssetCount + this.lockedAssetCount + this.availableAssetCount
  }

  public get usage() {
    return this.totalAssetCount === 0
      ? 1
      : (this.awardedAssetCount + this.lockedAssetCount) / this.totalAssetCount
  }

  public get urlEdit() {
    return `/plans/setup?planId=${this.id}`
  }

  public get urlDetail() {
    return this.urlEdit + '&detail=true'
  }

  public getTooltip(): string | undefined {
    if (this.isEditable) {
      return
    }

    if (this.hasRunningProcesses) {
      return `You can't edit while there are pending processes`
    }

    return `You can't edit custom plan`
  }

  public get hasRunningProcesses(): boolean {
    return !!this.lockedAssetCount
  }

  public get isEditable(): boolean {
    return !this.isCustom && !this.hasRunningProcesses
  }
}
