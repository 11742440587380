import { DateTime } from 'ts-luxon'
import BaseModel from './BaseModel'

export default class CompanyShare extends BaseModel {
  public id!: number
  public shares!: number
  public validFrom?: string

  public get validFromDateTime() {
    return this.validFrom ? DateTime.fromISO(this.validFrom).startOf('day') : undefined
  }
}
