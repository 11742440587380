import type DocumentModel from '~/models/Document'
import * as entityModels from './Activity/index'
import BaseModel from './BaseModel'
import { DateTime } from 'ts-luxon'
import User from './User'

export default class Activity extends BaseModel {
  public activityStreamType!: string
  public assetProcess!: {
    archivistUser?: {
      name: string
    }
    createdAt: string
    displayId: string
    esopSchemaName: string
    initiatorUser: {
      name: string
    }
    participant: {
      name: string
    }
  }
  public createdAt!: string
  public data!: {
    signer: {
      email: string
      name: string
      reason?: string
    }
    buybackProcess: {
      id: number
      displayId: string
      buybackCardinality: number
    }
    exerciseProcess: {
      id: number
      displayId: string
      exercisedCardinality: number
      currentCardinality: number
      exerciseRound: {
        id: number
        name: string
      }
    }
    exerciseRound: {
      id: number
      name: string
      lapsedCardinality: number
      currentCardinality: number
    }
    terminationProcess: {
      id: number
      displayId: string
      terminatedCardinality: number
      currentCardinality: number
    }
    userFormDetails: {
      reason: string
      user: {
        name: string
      }
    }
    vestedAssetCount: number
    note?: string
    unvested: number
    vestingEndDate: string
    vestingPause: {
      startDate: string
      endDate: string
      reason: string
    }
    allocatedCardinality: number
    deallocatedCardinality: number
    vestingMilestone: {
      performanceCriteria: string
      vestedOnDate: string
    }
    change: {
      numberOfAssets?: { original: number; new: number }
      vestingPeriod?: { original: string; new: string }
      vestingEnd?: { original: DateTime; new: DateTime }
    }
  }
  public user?: User
  public document?: DocumentModel
  public round!: {
    createdAt: string
    esopSchemaName: string
    initiatorUser: {
      name: string
    }
    name: string
    participantDeadline: string
  }
  public id!: number

  private get chunks(): string[] {
    return this.activityStreamType.split(':')
  }

  public get entity(): string {
    return this.chunks[0]
  }

  public get entityModel() {
    return entityModels[this.entity as keyof typeof entityModels]
  }

  public get entityLabel() {
    return this.entityModel.label
  }

  public get documentType(): string | undefined {
    const documentCode =
      this.chunks.length === 3 && this.chunks[2].startsWith('DOCUMENT_') ? this.chunks[1] : ''
    switch (documentCode) {
      case 'AGREEMENT':
        return 'transfer agreement'
      case 'NOTICE':
        return 'notice'
      default:
        return undefined
    }
  }

  public get action(): string {
    return this.chunks[this.chunks.length - 1]
  }

  public get isCustom(): boolean {
    return this.data && !!this.data.note
  }
}
