import { useMemo } from 'react'
import { useMount } from 'react-use'
import FooterNote from '~/components/common/FooterNote'
import ProgressBar from '~/components/common/ProgressBar'
import Button from '~/components/globals/Button'
import Card from '~/components/globals/Card'
import Loader from '~/components/utils/Loader'
import { useIntercom } from '~/hooks/intercom'
import { useModals } from '~/hooks/modals'
import { useWizard, type OnboardingStepStates, type OnboardingStepTypes } from '~/hooks/wizard'
import Item, { type StepItemProps } from './onboarding/Item'

const categoryTitles = {
  plan: 'Plan settings',
  data: 'Data import',
  award: 'Adoption'
} as const

type StepCategory = keyof typeof categoryTitles
type StepCategoryTitles = (typeof categoryTitles)[StepCategory]

const Onboarding = () => {
  const modals = useModals()
  const { openWindow } = useIntercom()
  const {
    countOfSteps,
    countOfFinishedSteps,
    steps,
    isLoading,
    areStepsUpdated,
    fetchOnboardingSteps
  } = useWizard()

  const content: Record<StepCategory, Record<string, StepItemProps>> = {
    plan: {
      'setup-company': {
        title: 'Create company profile',
        description: 'Enter all required details about your company.',
        to: '/settings/company-profile/edit'
      },
      'create-plan': {
        title: 'Choose a plan',
        description: 'Pick one of our standard ESOP plans.',
        to: '/plans/setup'
      }
    },
    data: {
      'add-valuation': {
        title: 'Add valuation',
        description:
          'Set up one or more valuations to communicate ESOP portfolio value to your team.',
        to: '/company/valuations/new'
      },
      'add-team-members': {
        title: 'Add team members',
        description: 'Invite other operators and approvers and select company signers.',
        to: '/settings/team-mates/new'
      },
      'import-participants': {
        title: 'Import participants',
        description: 'Import or manually add your ESOP participants.',
        to: '/people'
      }
    },
    award: {
      'make-award': {
        title: 'Make your first award',
        description: 'Start motivating your team with equity.',
        action: () => modals.open(import('~/modals/awards/NewAward'))
      }
    }
  } as const

  const stepsData = useMemo(() => {
    return Object.keys(steps).reduce((data, key) => {
      const [category, action] = key.split(':') as [StepCategory, OnboardingStepStates]
      const status = steps[key as OnboardingStepTypes] as OnboardingStepStates

      const title = categoryTitles[category]
      const item = { ...content[category]?.[action], status }

      if (title in data) {
        data[title].push(item)
      } else {
        data[title] = [item]
      }

      return data
    }, {} as Record<StepCategoryTitles, StepItemProps[]>)
  }, [steps])

  useMount(async () => {
    areStepsUpdated && (await fetchOnboardingSteps())
  })

  return isLoading ? (
    <div className="flex items-center justify-center pa-50">
      <Loader className="color-grey" />
    </div>
  ) : (
    <div className="fs-14">
      <h1>Welcome to the Eldison platform!</h1>
      <p className="mt-4 mb-30 color-grey-700">
        Explore it on your own or use this wizard to help you.
      </p>

      <Card shadow className="color-grey-900">
        <div className="border-bottom-grey-200 pb-24">
          <h3 className="fw-600">Setup wizard</h3>
          <p className="mt-4 mb-24 color-grey-700">
            This wizard will help you set up your account and get you ready for the first award.
          </p>

          <ProgressBar
            color="primary"
            bg="grey-100"
            value={countOfFinishedSteps}
            endValue={countOfSteps}
            height={8}
            showPercentage={false}
          />
        </div>

        {(Object.keys(stepsData) as StepCategoryTitles[]).map((category) => (
          <div key={category}>
            <h4 className="fw-600 my-24">{category}</h4>

            <div className="row g-2">
              {stepsData[category].map((item, i) => (
                <div key={category + i} className="col col-12">
                  <Item {...item} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </Card>

      <FooterNote>
        <>
          Need help?{' '}
          <Button variant="text" size="sm" className="underline" onClick={openWindow}>
            Chat with us!
          </Button>
        </>
      </FooterNote>
    </div>
  )
}

export default Onboarding
