import clsx from 'clsx'
import React, { type ButtonHTMLAttributes, type ReactNode, type MouseEvent } from 'react'
import { CSSTransition } from 'react-transition-group'

import styles from './Button.module.scss'
import ULink from './ULink'
import SvgIcon from '~/components/common/SvgIcon'

export type Variant = 'default' | 'icon' | 'text' | 'outline'
export type Size = 'default' | 'sm' | 'lg' | 'xs'

type Props = {
  label?: string
  to?: any
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
  variant?: Variant
  width?: string
  size?: Size
  icon?: string
  iconSize?: number
  color?: string
  textColor?: string
  className?: string
  children?: ReactNode
  prefix?: JSX.Element
  prefixIcon?: string
  suffix?: JSX.Element
  onClick?: (e: MouseEvent) => void
  disabled?: boolean
  loading?: boolean
}

const Button = React.forwardRef((props: Props, ref) => {
  const variant = props.variant ?? (props.icon ? 'icon' : 'default')
  const size = props.size ?? 'default'
  const isLink = !!props.to

  const Component = isLink ? (ULink as any) : 'button'

  const classNames = [styles.button, 'variant-' + variant, 'size-' + size]
  props.icon && props.variant && classNames.push('variant-icon')
  props.width && classNames.push('width-' + props.width)
  props.className && classNames.push(props.className)
  props.color && classNames.push('bg-' + props.color)
  props.textColor && classNames.push('color-' + props.textColor)

  const bind: Record<string, any> = {
    onClick: props.onClick
  }

  if (isLink) {
    bind.to = props.to
  } else {
    bind.type = props.type ?? 'button'
  }

  return (
    <Component
      className={clsx(classNames, {
        'is-loading': props.loading
      })}
      {...bind}
      ref={ref}
      disabled={props.disabled}
    >
      <span className={styles.inner}>
        {props.prefix}
        {!props.prefix && props.prefixIcon && (
          <SvgIcon icon={props.prefixIcon} height={20} className="mr-8" />
        )}

        {props.icon ? (
          <SvgIcon icon={props.icon} height={props.iconSize ?? 24} />
        ) : (
          props.children ?? props.label
        )}

        {props.suffix}
      </span>

      <CSSTransition
        addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
        in={props.loading}
        classNames="fade"
        unmountOnExit
      >
        <span className={styles.spinner}></span>
      </CSSTransition>
    </Component>
  )
})
Button.displayName = 'Button'

export default Button
