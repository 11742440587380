import React from 'react'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import ActivityAssetProcessLink from '~/components/common/ActivityStreamItem/ActivityAssetProcessLink'
import ActivityRoundLink from '~/components/common/ActivityStreamItem/ActivityRoundLink'
import { useFormatters } from '~/hooks/formatters'
import type Activity from '~/models/Activity'
import ExerciseRound from '~/models/ExerciseRound'
import { type ActivityAction } from '~/types/activityActions'

const ExercisedByExerciseWindowEnd: ActivityAction = ({ activity }: ActivityStreamItemProps) => {
  const { formatNormalNumber } = useFormatters()

  return (
    <div>
      {activity.entityLabel} ID
      <span>&nbsp;</span>
      <ActivityAssetProcessLink activity={activity} />:{' '}
      {formatNormalNumber(activity.data.exerciseRound.lapsedCardinality)} assets have not been
      exercised in the round
      <ActivityRoundLink
        activity={
          {
            entityModel: ExerciseRound,
            round: activity.data.exerciseRound
          } as unknown as Activity
        }
        className="fw-700"
      />
      and therefore have lapsed.
    </div>
  )
}

export default ExercisedByExerciseWindowEnd
