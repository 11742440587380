import React from 'react'
import { useFormatters } from '~/hooks/formatters'

type Props = {
  vestedAssetValue: number | null
}

export function PortfolioAssetValue(props: Props) {
  const { formatAmount } = useFormatters()

  return props.vestedAssetValue ? (
    <span className="color-grey-500 nowrap">
      <span className="color-black">{formatAmount(props.vestedAssetValue || 0)}</span>
    </span>
  ) : (
    <>-</>
  )
}
