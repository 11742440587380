import Link from 'next/link'
import { useWizard } from '~/hooks/wizard'
import ActivityStream from '../common/ActivityStream'
import SvgIcon from '../common/SvgIcon'
import Button from '../globals/Button'
import CompanyValuation from '../templates/CompanyValuation'
import PlansOverview from '../templates/PlansOverview'
import CustomGuide from './CustomGuide'
import ExerciseRoundsList from './dashboard-operator/ExerciseRoundsList'
import Onboarding from './dashboard-operator/Onboarding'

export default function DashboardOperator() {
  const { isWizardComplete } = useWizard()

  return (
    <>
      {isWizardComplete ? (
        <div className="row spacing-m">
          <div className="col col-4@xl">
            <PlansOverview />
            <CompanyValuation className="mt-16" />
            <CustomGuide className="mt-32" />
          </div>

          <div className="col col-8@xl">
            <ExerciseRoundsList className="mb-32" />

            <ActivityStream
              link={
                <Link href="/activity-stream">
                  <Button variant="text" size="xs">
                    <>
                      See all activities{' '}
                      <SvgIcon icon="chevron-right" height={14} className="ml-4" />
                    </>
                  </Button>
                </Link>
              }
            />
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col col-8@lg offset-lg-2">
            <Onboarding />
          </div>
        </div>
      )}
    </>
  )
}
