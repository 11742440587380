import React from 'react'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import ActivityAssetProcessLink from '~/components/common/ActivityStreamItem/ActivityAssetProcessLink'
import { useFormatters } from '~/hooks/formatters'
import { type ActivityAction } from '~/types/activityActions'

const VestingRestarted: ActivityAction = ({ activity }: ActivityStreamItemProps) => {
  const { formatDate } = useFormatters()
  return (
    <div>
      Vesting has been restarted for {activity.entityLabel} ID{' '}
      <ActivityAssetProcessLink activity={activity} /> after being paused from{' '}
      {formatDate(activity.data.vestingPause.startDate)} to{' '}
      {formatDate(activity.data.vestingPause.endDate)} due to {activity.data.vestingPause.reason}.
    </div>
  )
}

export default VestingRestarted
