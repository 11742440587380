const timeouts: { [key: string]: NodeJS.Timeout | number } = {}
const invocations: { [key: string]: any } = {}

export function lazyAsync(fn: (...args: any[]) => any, debounce = 0, id = Math.random()) {
  const invoke = async (context: any, args: any) => {
    const key = context && context._isVue ? context._uid : id

    let invocation = invocations[key]

    if (invocation) {
      invocation.next = { context, args }
      return
    }

    invocation = {}
    invocations[key] = invocation

    // wait for microtask queue
    await new Promise((resolve) => {
      resolve(null)
    })

    let err
    try {
      await fn.apply(context, args)
    } catch (_err) {
      err = _err
    }

    if (invocation.next) {
      const next = invocation.next

      setTimeout(() => {
        invoke(next.context, next.args)
      }, 0)
    }

    delete invocations[key]

    if (err) {
      throw err
    }
  }

  return function (this: any, ...args: any[]) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this

    const key = context && context._isVue ? context._uid : id

    const timeout = timeouts[key]

    if (timeout) {
      clearTimeout(timeout as number)
    }

    timeouts[key] = setTimeout(() => {
      delete timeouts[key]

      invoke(context, args)
    }, debounce)
  }
}

export function get(obj: any, prop: string) {
  const parts = prop.split('.')

  for (const part of parts) {
    if (!obj) {
      return
    }

    obj = obj[part]
  }

  return obj
}

export const mapValueToLabel = (value: string | null, list: Record<string, any>[]) => {
  return list.find((item) => item.value === value)?.label ?? ''
}

export const getPathname = (path: string) => {
  return new URL(path, location.href).pathname
}

export const sleep = async (ms: number) => {
  return new Promise((r) => setTimeout(r, ms))
}

export const getInitials = (name: string) => {
  if (name.length === 0) {
    return '-'
  }

  return name
    .split(/\s+/)
    .map((item) => item.trim().substring(0, 1))
    .filter((item) => item)
    .slice(0, 1)
    .join('')
    .toUpperCase()
}

export function downloadFile(file: any, filename: string) {
  const tmpUrl = window.URL.createObjectURL(
    new Blob([file as unknown as BlobPart], {
      type: (file as unknown as { type: string }).type ?? 'application/octet-stream'
    })
  )
  const link = document.createElement('a')
  link.href = tmpUrl
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  window.URL.revokeObjectURL(tmpUrl)
}
