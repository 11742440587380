import React from 'react'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import ActivityAssetProcessLink from '~/components/common/ActivityStreamItem/ActivityAssetProcessLink'
import { type ActivityAction } from '~/types/activityActions'

const DocumentUploaded: ActivityAction = ({ activity }: ActivityStreamItemProps) => {
  return (
    <div>
      Document <b>{activity.document?.name}</b> has been uploaded to {activity.entityLabel} ID
      <span>&nbsp;</span>
      <ActivityAssetProcessLink activity={activity} />
      <span>&nbsp;</span>
      by {activity.assetProcess?.initiatorUser?.name || '-'}
    </div>
  )
}

export default DocumentUploaded
