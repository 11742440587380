import React from 'react'
import { DateTime } from 'ts-luxon'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import { useFormatters } from '~/hooks/formatters'
import { type ActivityAction } from '~/types/activityActions'

const Initiated: ActivityAction = ({ activity }: ActivityStreamItemProps) => {
  const { formatDate } = useFormatters()
  return (
    <>
      <div>
        New {activity.entityLabel} for {activity.round.esopSchemaName}, round ID{' '}
        <span className="fw-700">{activity.round.name}</span> has been initiated.
      </div>
      <div className="pt-4">
        Deadline for this exercise round is{' '}
        {formatDate(activity.round?.participantDeadline, DateTime.DATE_MED)}.
      </div>
    </>
  )
}

export default Initiated
