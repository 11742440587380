import BadgeStatus from '~/components/common/BadgeStatus'
import type Participant from '~/models/Participant'

type Props = {
  participant: Participant
}

export function ParticipantFlags(props: Props) {
  if (!props.participant.flags) {
    return <>-</>
  }
  const flags = props.participant.flags.split(', ')
  return (
    <>
      {flags.map((flag) => (
        <BadgeStatus key={flag} status={flag} color={flag === 'Leaver' ? 'orange' : 'default'} />
      ))}
    </>
  )
}
