import React from 'react'
import { DateTime } from 'ts-luxon'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import ActivityAssetProcessLink from '~/components/common/ActivityStreamItem/ActivityAssetProcessLink'
import { useFormatters } from '~/hooks/formatters'
import { type ActivityAction } from '~/types/activityActions'

const Approved: ActivityAction = ({ activity }: ActivityStreamItemProps) => {
  const { formatDate } = useFormatters()

  return (
    <div>
      {activity.entityLabel} ID
      <span>&nbsp;</span>
      <ActivityAssetProcessLink activity={activity} />
      <span>&nbsp;</span>
      has been changed:
      {activity.data.change.numberOfAssets && (
        <div className="pt-4">
          - number of assets: {activity.data.change.numberOfAssets.original} -&gt;{' '}
          {activity.data.change.numberOfAssets.new}
        </div>
      )}
      {activity.data.change.vestingPeriod && (
        <div className="pt-4">
          - vesting period: {activity.data.change.vestingPeriod.original} -&gt;{' '}
          {activity.data.change.vestingPeriod.new}
        </div>
      )}
      {activity.data.change.vestingEnd && (
        <div className="pt-4">
          - vesting end: {formatDate(activity.data.change.vestingEnd.original, DateTime.DATE_MED)}{' '}
          -&gt; {formatDate(activity.data.change.vestingEnd.new, DateTime.DATE_MED)}
        </div>
      )}
    </div>
  )
}

export default Approved
