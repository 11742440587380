import React from 'react'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import ActivityAssetProcessLink from '~/components/common/ActivityStreamItem/ActivityAssetProcessLink'
import { useFormatters } from '~/hooks/formatters'
import { type ActivityAction } from '~/types/activityActions'

const VestingPaused: ActivityAction = ({ activity }: ActivityStreamItemProps) => {
  const { formatDate } = useFormatters()
  return (
    <div>
      Vesting has been paused for {activity.entityLabel} ID{' '}
      <ActivityAssetProcessLink activity={activity} /> from{' '}
      {formatDate(activity.data.vestingPause.startDate)} to{' '}
      {formatDate(activity.data.vestingPause.endDate)} due to {activity.data.vestingPause.reason}.
      Vesting end date has been moved to {formatDate(activity.data.vestingEndDate)}.
      {activity.data.unvested
        ? ` ${activity.data.unvested} already vested asset${
            activity.data.unvested > 1 ? 's' : ''
          } were unvested.`
        : null}
    </div>
  )
}

export default VestingPaused
