import React from 'react'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import ActivityAssetProcessLink from '~/components/common/ActivityStreamItem/ActivityAssetProcessLink'
import { type ActivityAction } from '~/types/activityActions'

const ToBeApproved: ActivityAction = ({ activity }: ActivityStreamItemProps) => {
  return (
    <div>
      {activity.entityLabel} ID
      <span>&nbsp;</span>
      <ActivityAssetProcessLink activity={activity} /> is pending an approval.
    </div>
  )
}

export default ToBeApproved
