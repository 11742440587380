import React from 'react'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import { type ActivityAction } from '~/types/activityActions'

const WindowClosed: ActivityAction = ({ activity }: ActivityStreamItemProps) => {
  return (
    <div>
      Exercise window for {activity.round.esopSchemaName}, round{' '}
      <span className="fw-700">{activity.round.name}</span> has closed.
    </div>
  )
}

export default WindowClosed
