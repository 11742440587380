import LogoSvg from '~/assets/svgs/logo.svg'
import LineLoader from './LineLoader'

export default function Splash() {
  return (
    <div className="splash fullscreen flex items-center">
      <LineLoader absolute />

      <div className="container text-center color-black">
        <LogoSvg />
      </div>
    </div>
  )
}
