import { useRouter } from 'next/router'
import BadgeStatus from '~/components/common/BadgeStatus'
import SvgIcon from '~/components/common/SvgIcon'
import Button from '~/components/globals/Button'
import Card from '~/components/globals/Card'
import { useFormatters } from '~/hooks/formatters'
import { useRounds } from '~/hooks/rounds'
import ExerciseRound from '~/models/ExerciseRound'

type Props = {
  className?: string
}

export default function ExerciseRoundsList({ className }: Props) {
  const { formatDate } = useFormatters()
  const { openRounds } = useRounds('exercise', ExerciseRound)

  const router = useRouter()

  const onAction = (round: ExerciseRound) => {
    return router.push(round.urlDetail)
  }

  return (
    <>
      {(openRounds ?? []).map((round) => (
        <Card
          key={round.id}
          title={round.name}
          description={`Exercise round,
           ${formatDate(round.data.exerciseStart)} - ${formatDate(round.data.exerciseEnd)}`}
          actions={<BadgeStatus status={round?.status} color={round?.statusColor} />}
          className={className}
          headerClassName="pb-16"
        >
          <div className="pt-10">
            <Button variant="text" size="xs" onClick={() => onAction(round)}>
              See the detail <SvgIcon icon="chevron-right" height={14} className="ml-4" />
            </Button>
          </div>
        </Card>
      ))}
    </>
  )
}
