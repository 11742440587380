import { DateTime } from 'ts-luxon'

export const parseStringIntoDateTime = (date: string): DateTime | undefined => {
  const allowedFormats = [
    'yyyy-MM-dd',
    'yyyy-MM-dd HH:mm',
    'yyyy-MM-dd HH:mm:ss',
    'yyyy-MM-dd HH:mm:ss.SSS',
    'dd.MM.yyyy',
    'd.M.yyyy',
    'dd.MM.yyyy HH:mm',
    'dd.MM.yyyy HH:mm:ss',
    'dd.MM.yyyy HH:mm:ss.SSS',
    'dd. MM. yyyy',
    'd. M. yyyy',
    'dd. MM. yyyy HH:mm',
    'dd. MM. yyyy HH:mm:ss',
    'dd. MM. yyyy HH:mm:ss.SSS',
    'd.M.yyyy HH:mm:ss',
    'd.M.yyyy HH:mm:ss.SSS',
    'dd/MM/yyyy',
    'dd/MM/yyyy HH:mm',
    'dd/MM/yyyy HH:mm:ss',
    'dd/MM/yyyy HH:mm:ss.SSS',
    'd/M/yyyy HH:mm',
    'd/M/yyyy HH:mm:ss',
    'd/M/yyyy HH:mm:ss.SSS',
    'dd-MM-yyyy',
    'dd-MM-yyyy HH:mm',
    'dd-MM-yyyy HH:mm:ss',
    'dd-MM-yyyy HH:mm:ss.SSS',
    `yyyy-MM-dd'T'HH:mm:ss.SSSZZ`,
    `yyyy-MM-dd'T'HH:mm:ss`,
    `yyyy-MM-dd'T'HH:mm:ssZZ`
  ]
  for (const format of allowedFormats) {
    const dateCheck = DateTime.fromFormat(date, format)
    if (DateTime.fromFormat(date, format).isValid) {
      return dateCheck
    }
  }
}

export const firstLetterUppercase = (s: string) => {
  return (s ?? '').slice(0, 1).toUpperCase() + (s ?? '').slice(1).toLowerCase()
}

export const pluralize = (str: string, count: number) => {
  return count > 1 ? str + 's' : str
}
