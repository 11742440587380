import React from 'react'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import { type ActivityAction } from '~/types/activityActions'

const Closed: ActivityAction = ({ activity }: ActivityStreamItemProps) => {
  return (
    <div>
      {activity.entityLabel} <span className="fw-700">{activity.round.name}</span> has closed.
    </div>
  )
}

export default Closed
