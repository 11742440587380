import { create } from 'zustand'
import { combine } from 'zustand/middleware'
import type Modal from '~/models/Modal'

export const useModalsStore = create(
  combine(
    {
      list: [] as Modal[]
    },
    (set, get) => ({
      add(modal: Modal) {
        const list = get().list.slice(0)
        list.push(modal)

        set({ list })
      },
      remove(modal: Modal) {
        const list = get().list.slice(0)
        list.splice(list.indexOf(modal), 1)

        set({ list })
      }
    })
  )
)
