import React from 'react'
import Document from '~/components/common/Document'
import type DocumentModel from '~/models/Document'
import styles from './TimelineItem.module.scss'
import SvgIcon from '~/components/common/SvgIcon'
import clsx from 'clsx'

export interface ITimelineItem {
  icon: string
  header: string | React.ReactElement
  detail?: string | React.ReactElement
  date: string
  document?: DocumentModel
}

type Props = {
  item: ITimelineItem
}

export default function TimelineItem({ item }: Props) {
  return (
    <li className={styles.item}>
      <div className={clsx(styles.icon, 'flex-auto', 'mr-16', 'skeleton-avatar')}>
        <SvgIcon icon={item.icon} height={20} className="fs-12" />
      </div>

      <div className="flex-fill">
        <div className="fs-14">
          <span className="skeleton-name">{item.header}</span>
        </div>
        <div className="fs-12 color-grey pt-4 pb-16">
          <span className="skeleton-value">{item.date}</span>
        </div>
        {item.detail ? <div className="fs-12 color-grey pb-16">{item.detail}</div> : null}
        {item.document ? <Document className="mb-16" document={item.document} /> : null}
      </div>
    </li>
  )
}
