import { useReducer } from 'react'
import { useMount } from 'react-use'
import type Round from '~/models/Round'
import { useTenant } from './tenant'

type RoundTypes = 'exercise' | 'dividendPayout'

type RoundsState<T> = {
  rounds: T[]
  openRounds: T[]
  closedRounds: T[]
  draftRounds: T[]
  pendingRounds: T[]
  isLoading: boolean
}

const initialState = {
  rounds: [],
  openRounds: [],
  closedRounds: [],
  draftRounds: [],
  pendingRounds: [],
  isLoading: true
}

export const useRounds = <T extends Round>(
  roundType: RoundTypes,
  Model: new (data: object) => T,
  options?: {
    sortFn?: (a: T, b: T) => number
    params?: Record<string, any>
  }
) => {
  const { tenantApi } = useTenant()
  const [roundsState, dispatch] = useReducer(
    (state: RoundsState<T>, payload: Partial<RoundsState<T>>) => ({ ...state, ...payload }),
    initialState
  )

  const getRounds = async () => {
    let rounds: T[] = (
      await tenantApi.$get('rounds', { params: { roundType, ...options?.params } })
    ).map((d: object) => new Model(d))
    rounds = options?.sortFn ? rounds.sort(options.sortFn) : rounds
    const open = []
    const closed = []
    const draft = []
    const pending = []

    for (const round of rounds) {
      switch (round.status) {
        case 'open':
          open.push(round)
          break
        case 'closed':
          closed.push(round)
          break
        case 'draft':
          draft.push(round)
          break
        case 'pending':
          pending.push(round)
      }
    }

    dispatch({
      rounds,
      openRounds: open,
      closedRounds: closed,
      draftRounds: draft,
      pendingRounds: pending,
      isLoading: false
    })
  }

  const isEmpty = roundsState.rounds?.length === 0

  useMount(getRounds)

  return {
    ...roundsState,
    isEmpty
  }
}
