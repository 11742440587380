import BaseModel from '~/models/BaseModel'

export default class Notification extends BaseModel {
  public id!: number
  public type!: string
  public link?: string
  public message!: string
  public color!: string
  public readAt!: string
  public createdAt!: string
}
