import type Award from '~/models/AssetProcess/Award'
import { DateTime } from 'ts-luxon'
import ProgressBar from '~/components/common/ProgressBar'
import React from 'react'
import { useFormatters } from '~/hooks/formatters'

type Props = {
  award: Award
}

export function AwardBeingVested({ award }: Props) {
  const { formatDate } = useFormatters()
  return (
    <div>
      <div>
        {award.hasVesting ? (
          <span className="nowrap">
            {formatDate(award.vestingStartDate, DateTime.DATE_MED)}
            {' - '}
            {formatDate(award.vestingEndDate, DateTime.DATE_MED)}
          </span>
        ) : null}
      </div>
      <div className="mt-8">
        <ProgressBar value={award.vestingProgressPercent} color="primary" height={8} />
      </div>
    </div>
  )
}
