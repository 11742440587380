import { useFormatters } from '~/hooks/formatters'
import { ITooltip } from '../graphs/LineGraph'
import TooltipCard from '../graphs/TooltipCard'
import { useValuation } from '~/hooks/valuation'
import { DateTime } from 'ts-luxon'
import ValuationModel from '~/models/Company/ValuationModel'

type Props = ITooltip & {
  companyValuations: ValuationModel[]
  date: DateTime
}

export default function CompanyValuationGraphTooltip(props: Props) {
  const { formatAmountPrecise } = useFormatters()
  const { getValuationPricePerAsset } = useValuation()

  return (
    <TooltipCard
      {...props}
      items={props.items.map((item) => ({
        ...item,
        footerComponent: props.companyValuations[item.datasetIndex] ? (
          <div className="pl-12 pt-4 pb-8">
            Price per asset:{' '}
            {formatAmountPrecise(
              getValuationPricePerAsset(props.companyValuations[item.datasetIndex], props.date)
            )}
          </div>
        ) : undefined
      }))}
    />
  )
}
