import React from 'react'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import InnerLink from '~/components/globals/InnerLink'
import Participant from '~/models/Participant'

export default function Termination({ activity }: ActivityStreamItemProps) {
  return (
    <>
      <div>
        Termination details:{' '}
        <InnerLink withURLs={new Participant(activity.assetProcess.participant)} />
      </div>
    </>
  )
}
