import styles from './Popper.module.scss'
import { createPopper, type Instance, type Placement } from '@popperjs/core'
import React, { type ReactNode, useEffect, useRef, useState } from 'react'
import { useUnmounted } from '~/hooks/component'
import { useOutsideClick } from '~/hooks/click'

type Props = {
  className?: string
  activator: ReactNode
  children?: ReactNode
  tag?: keyof JSX.IntrinsicElements
  value: boolean
  stretch?: boolean
  onChange: (value: boolean) => void
  placement?: Placement
  appendToBody?: boolean
  offset?: number[]
}

export default function Popper(props: Props) {
  const placement = props.placement ?? 'bottom'
  const offset = props.offset ?? [0, 0]
  const appendToBody = props.appendToBody ?? false

  const tryClose = (e: MouseEvent) => {
    if (!popperEl.current?.contains(e.target as Node) && e.target !== document.body) {
      props.onChange(false)
    }
  }

  const root = useOutsideClick(tryClose)
  const popperEl = React.useRef() as React.MutableRefObject<HTMLInputElement>
  const popper = useRef<Instance | undefined>()
  const [isAppendedToBody, setIsAppendedToBody] = useState(false)
  const shouldRenderPopper = props.value || isAppendedToBody

  const destroyPopper = () => {
    if (appendToBody && isAppendedToBody && popperEl.current) {
      root.current?.appendChild(popperEl.current)
      popperEl.current.style.opacity = '0'
      setIsAppendedToBody(false)
    }

    if (popper.current) {
      popper.current.destroy()
      popper.current = undefined
    }
  }

  const setupPopper = () => {
    if (!popperEl.current || !root.current) {
      return
    }

    if (appendToBody && !isAppendedToBody) {
      document.body.appendChild(popperEl.current)
      setIsAppendedToBody(true)
    }

    if (props.stretch !== false) {
      const width = root.current?.clientWidth ?? 0
      popperEl.current.style.minWidth = `${width}px`
    }

    popper.current = createPopper(root.current, popperEl.current, {
      placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset
          }
        },
        {
          name: 'preventOverflow',
          options: {
            rootBoundary: 'document',
            padding: 5
          }
        }
      ]
    })
  }

  const updatePopper = () => {
    if (props.value) {
      !popper.current && setupPopper()
    } else if (popper.current) {
      destroyPopper()
    }
  }

  useUnmounted(destroyPopper)

  useEffect(updatePopper, [props.value, destroyPopper, setupPopper])

  return React.createElement(
    props.tag ?? 'div',
    { ref: root, className: props.className },
    <>
      {props.activator}
      {shouldRenderPopper && (
        <div ref={popperEl} className={styles.popper}>
          {props.children}
        </div>
      )}
    </>
  )
}
