import { HTMLAttributeAnchorTarget } from 'react'
import Pressable from '~/components/globals/Pressable'
import { useAcl } from '~/hooks/user'
import { type WithURLs } from '~/models/BaseModel'
import Participant from '~/models/Participant'

type Props = {
  withURLs?: WithURLs
  target?: HTMLAttributeAnchorTarget
}

export default function InnerLink({ withURLs, target }: Props) {
  const user = useAcl()
  // // TODO remove this condition after participant detail is implemented for participant
  if (withURLs instanceof Participant && !user.can('manage_participants')) {
    return <>{withURLs?.urlLabel}</>
  }
  return (
    <>
      {withURLs?.urlDetail ? (
        <Pressable to={withURLs?.urlDetail} target={target}>
          <span className="color-secondary fw-700">{withURLs?.urlLabel}</span>
        </Pressable>
      ) : (
        <span className="skeleton-value" />
      )}
    </>
  )
}
