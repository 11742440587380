import AssetProcess from '~/models/AssetProcess'
import { type WithURLs } from '~/models/BaseModel'
import Award from '~/models/AssetProcess/Award'

export type BuybackAwardData = {
  awardId: number
  pricePerAsset: number
  unvestedCardinality: number
  vestedCardinality: number
}

type BuybackData = {
  personalEmail: string
  buybackDate: string
  buybackAwards: BuybackAwardData[]
  totalBuybackCardinality: number
  totalBuybackPrice: number
}

export default class Buyback extends AssetProcess implements WithURLs {
  static label = 'Buyback request'

  public static mapping = {
    ...AssetProcess.baseMapping,
    awards: () => Award
  }
  public data!: BuybackData
  public awards!: Award[]

  public buybackDate!: string

  public paymentInstructions?: {
    holderName: string
    iban: string
    swift: string
  }

  public get urlDetail() {
    return `/buybacks/${this.id}`
  }

  public get urlEdit() {
    return `/buybacks/edit/${this.id}`
  }

  public get urlLabel(): string {
    return this.displayId
  }
}
