import styles from './Modals.module.scss'
import { useModalsStore } from '~/stores/modals'
import { CSSTransition } from 'react-transition-group'
import clsx from 'clsx'

export default function Modals() {
  const list = useModalsStore((state) => state.list)

  return (
    <CSSTransition
      in={!!list.length}
      addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
      classNames="fade"
      unmountOnExit
    >
      <div className={styles.modals}>
        {list.map((modal) => {
          const Component = modal.component

          return <Component key={modal.id} modal={modal} {...modal.params} />
        })}

        <div className={clsx(styles.bg, 'over-fill')} />
      </div>
    </CSSTransition>
  )
}
