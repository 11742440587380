import { create } from 'zustand'
import { combine } from 'zustand/middleware'
import { globalApi } from '~/services/APIService'

export type Config = Record<string, any>

export const useStore = create(
  combine(
    {
      config: {} as Config,
      isSidebarToggled: false
    },
    (set, get) => ({
      setConfig(config: Config) {
        set({ config })
      },
      setIsSidebarToggled(isSidebarToggled: boolean) {
        set({ isSidebarToggled })
      },
      toggleSidebar(isSidebarToggled?: boolean) {
        set({ isSidebarToggled: isSidebarToggled ?? !get().isSidebarToggled })
      },
      async fetchConfig() {
        const config = await globalApi.$get('config')
        set({ config })
      }
    })
  )
)
