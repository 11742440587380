import React from 'react'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import ActivityAssetProcessLink from '~/components/common/ActivityStreamItem/ActivityAssetProcessLink'
import { type ActivityAction } from '~/types/activityActions'

const PaymentCompleted: ActivityAction = ({ activity }: ActivityStreamItemProps) => {
  return (
    <>
      <div>Payment processing confirmed by the participant.</div>
      <div className="pt-4">
        {activity.entityLabel} ID
        <span>&nbsp;</span>
        <ActivityAssetProcessLink activity={activity} />
      </div>
    </>
  )
}

export default PaymentCompleted
