import styles from './Badge.module.scss'
import clsx from 'clsx'

type Variant = 'default' | 'square'
type Size = 'default'

type BadgeProps = {
  variant?: Variant
  size?: Size
  color?: string
  textColor?: string
  className?: string
  children?: JSX.Element | string
}

export default function Badge(props: BadgeProps) {
  const variant = props.variant ?? 'default'
  const size = props.size ?? 'default'
  const Component = 'span'

  const classNames = [styles.badge, props.className, 'variant-' + variant, 'size-' + size]

  props.color && classNames.push('bg-' + props.color)
  props.textColor && classNames.push('color-' + props.textColor)

  return <Component className={clsx(classNames)}>{props.children}</Component>
}
