import { useStore } from '~/stores'
import { type ConfigType } from '~/types/app'

export const useTypes = () => {
  const userStore = useStore()

  const getType = (groupName: string): ConfigType[] => {
    const configsByGroup =
      userStore.config && userStore.config.types
        ? userStore.config.types.filter(
            (config: { group?: string }) => config.group && config.group === groupName
          )
        : []
    return configsByGroup.sort((itemA: ConfigType, itemB: ConfigType) =>
      itemA.label.toLowerCase().localeCompare(itemB.label.toLowerCase())
    )
  }

  const getTypeLabel = (groupName: string, value: string): string => {
    return (
      userStore.config.types?.find(
        (type: ConfigType) => type.group === groupName && type.value === value
      )?.label || value
    )
  }

  const getAssetLabelPlural = (value: string): string => {
    return `${getTypeLabel('asset', value)}s`
  }

  return {
    getType,
    getTypeLabel,
    getAssetLabelPlural
  }
}

export type Types = ReturnType<typeof useTypes>
