import React from 'react'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import ActivityAssetProcessLink from '~/components/common/ActivityStreamItem/ActivityAssetProcessLink'
import { type ActivityAction } from '~/types/activityActions'

const VestingMilestoneNotAchieved: ActivityAction = ({ activity }: ActivityStreamItemProps) => {
  return (
    <div>
      Performance milestone &quot;{activity.data.vestingMilestone.performanceCriteria}&quot; was set
      to <span className="fw-700">NOT ACHIEVED</span> for {activity.entityLabel} ID{' '}
      <ActivityAssetProcessLink activity={activity} />. {activity.data.deallocatedCardinality}{' '}
      {activity.data.deallocatedCardinality === 1 ? 'asset was' : 'assets were'} returned to the
      pool.
    </div>
  )
}

export default VestingMilestoneNotAchieved
