export class Exception extends Error {
  public isHandled: boolean

  constructor(message: string) {
    super(message)

    this.isHandled = true

    Object.setPrototypeOf(this, new.target.prototype)
  }
}
