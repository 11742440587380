import React from 'react'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import ActivityAssetProcessLink from '~/components/common/ActivityStreamItem/ActivityAssetProcessLink'
import { type ActivityAction } from '~/types/activityActions'

const PaymentInformationCheck: ActivityAction = ({ activity }: ActivityStreamItemProps) => {
  return (
    <>
      <div>
        {activity.entityLabel} ID <ActivityAssetProcessLink activity={activity} /> is pending
        participant bank account details confirmation.
      </div>
    </>
  )
}

PaymentInformationCheck.icon = 'clock-fill'

export default PaymentInformationCheck
