import React from 'react'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import ActivityAssetProcessLink from '~/components/common/ActivityStreamItem/ActivityAssetProcessLink'
import { type ActivityAction } from '~/types/activityActions'

const Requested: ActivityAction = ({ activity }: ActivityStreamItemProps) => {
  return (
    <div>
      Participant {activity.assetProcess.participant.name} has created {activity.entityLabel} ID{' '}
      <ActivityAssetProcessLink activity={activity} /> in the exercise round {activity.round.name}.
    </div>
  )
}

export default Requested
