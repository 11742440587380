import Badge from '~/components/globals/Badge'

type Props = {
  assetType: string
  className?: string
}

export default function BadgeAssetType(props: Props) {
  return (
    <Badge
      variant="square"
      color="orange-light"
      textColor="orange-dark"
      className={props.className}
    >
      {props.assetType.substring(0, 1).toUpperCase()}
    </Badge>
  )
}
