import { create } from 'zustand'
import { combine } from 'zustand/middleware'
import type ValuationModel from '~/models/Company/ValuationModel'
import type Tenant from '~/models/Tenant'
import type EsopPlan from '~/models/EsopPlan'
import type Tier from '~/models/Tier'
import type CurrentTier from '~/models/CurrentTier'
import CompanyShare from '~/models/CompanyShare'

export const useCompanyStore = create(
  combine(
    {
      tenant: {} as Tenant,
      valuations: [] as ValuationModel[],
      sharesSorted: [] as CompanyShare[],
      esopPlans: [] as EsopPlan[],
      tiers: [] as Tier[],
      isTierLoaded: false
    },
    (set, get) => ({
      setTiers(tiers: Tier[]) {
        set({ tiers, isTierLoaded: true })
      },
      setCompanyShares(sharesSorted: CompanyShare[]) {
        set({ sharesSorted })
      },
      setValuations(valuations: ValuationModel[]) {
        set({ valuations })
      },
      setTenant(tenant: Tenant) {
        set({ tenant })
      },
      setEsopPlans(esopPlans: EsopPlan[]) {
        set({ esopPlans })
      },
      setCurrentTier(currentTier: CurrentTier) {
        const tenant = get().tenant
        tenant.currentTier = currentTier

        set({ tenant })
      }
    })
  )
)
