import React from 'react'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import ActivityAssetProcessLink from '~/components/common/ActivityStreamItem/ActivityAssetProcessLink'
import { useFormatters } from '~/hooks/formatters'
import { type ActivityAction } from '~/types/activityActions'
import type Activity from '~/models/Activity'
import Buyback from '~/models/AssetProcess/Buyback'

const Buybacked: ActivityAction = ({ activity }: ActivityStreamItemProps) => {
  const { formatNormalNumber } = useFormatters()
  return (
    <>
      <div>
        {activity.entityLabel} ID
        <span>&nbsp;</span>
        <ActivityAssetProcessLink activity={activity} />:{' '}
        {formatNormalNumber(activity.data.buybackProcess.buybackCardinality)} assets returned to
        pool as a result of Buyback ID{' '}
        <ActivityAssetProcessLink
          activity={
            {
              entityModel: Buyback,
              assetProcess: activity.data.buybackProcess
            } as unknown as Activity
          }
          className="fw-700"
        />
        .
      </div>
    </>
  )
}

export default Buybacked
