import { useState } from 'react'
import { useTenant } from './tenant'
import { useWizardStore } from '~/stores/wizard'

export type OnboardingStepStates = 'enabled' | 'done' | 'locked'

export type OnboardingStepTypes =
  | 'plan:create-plan'
  | 'plan:setup-company'
  | 'data:add-valuation'
  | 'data:add-team-members'
  | 'data:import-participants'
  | 'award:make-award'

export type OnboardingSteps = Record<OnboardingStepTypes, OnboardingStepStates>

export const useWizard = () => {
  const { tenantApi } = useTenant()
  const wizardStore = useWizardStore()
  const [isLoading, setIsLoading] = useState(false)

  const fetchOnboardingSteps = async () => {
    setIsLoading(true)
    try {
      const res = await tenantApi.$get<{ steps: OnboardingSteps }>('tenant/onboarding')
      wizardStore.setSteps(res.steps)
    } catch (err) {
      //
    }
    setIsLoading(false)
  }

  const updateWizardStep = (step: OnboardingStepTypes, state: OnboardingStepStates = 'done') => {
    wizardStore.updateStep(step, state)
  }

  const countOfSteps = Object.keys(wizardStore.steps).length
  const countOfFinishedSteps = Object.values(wizardStore.steps).filter(
    (status) => status === 'done'
  ).length

  const isWizardComplete = countOfSteps > 0 && wizardStore.steps['award:make-award'] === 'done'
  const wizardProgress = (countOfFinishedSteps / countOfSteps) * 100

  return {
    isLoading,
    isWizardComplete,
    wizardProgress,
    countOfSteps,
    countOfFinishedSteps,
    areStepsUpdated: wizardStore.areStepsUpdated,
    showWizardBanner: wizardStore.showWizardBanner,
    steps: wizardStore.steps,
    setShowBanner: wizardStore.setShowBanner,
    fetchOnboardingSteps,
    updateWizardStep
  }
}
