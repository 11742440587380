import DividendRound from '~/models/DividendRound'
import AssetProcess from '~/models/AssetProcess'
import { type WithURLs } from '~/models/BaseModel'

export default class DividendPayout extends AssetProcess implements WithURLs {
  static label = 'Dividend payout request'

  public static mapping = {
    ...AssetProcess.baseMapping,
    round: () => DividendRound
  }

  public processAssetCount!: number
  public payout!: number

  public roundId!: number
  public round!: DividendRound

  public get urlDetail(): string {
    return `/dividends/${this.roundId}/detail/${this.id}`
  }

  public get urlLabel(): string {
    return this.displayId
  }
}
