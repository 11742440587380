import Modal, { type ModalOptions } from '~/models/Modal'
import { useModalsStore } from '~/stores/modals'
import { useEffect } from 'react'

export const useModals = () => {
  const modalsStore = useModalsStore()

  const open = async <T>(
    component: any | Promise<any>,
    options: ModalOptions = {},
    props: T = {} as T
  ) => {
    if (component instanceof Promise) {
      component = await component
    }
    const modal = new Modal(component.default || component, options, props)
    const promise = new Promise((resolve) => {
      modal.resolve = resolve
      modalsStore.add(modal)
    })
    modal.promise = promise
    return promise
  }

  const confirm = (props = {}, options: ModalOptions = {}) => {
    const component = import('~/modals/Confirm')

    return open(component, options, props)
  }

  useEffect(() => {
    if (modalsStore.list.length === 0) {
      document.body.classList.remove('is-modals-opened')
    } else {
      document.body.classList.add('is-modals-opened')
    }
  }, [modalsStore.list.length])

  const close = (modal: Modal, resolveValue?: unknown) => {
    if (modal.resolve) {
      modal.resolve(resolveValue)
      modal.resolve = undefined
      modal.promise = undefined
    }
    modalsStore.remove(modal)
  }

  return {
    open,
    confirm,
    close
  }
}
