import { type Middleware } from '~/types/middlewares'
import { type Permission } from '~/models/User'
import { ForbiddenException } from '~/exceptions/acl'

const aclMiddleware: Middleware = (context, ...permissions: Permission[]) => {
  if (permissions.some((permission) => !context.acl.can(permission))) {
    return {
      error: new ForbiddenException()
    }
  }
}

export default aclMiddleware
