import { type Page } from '~/types/pages'
import LogoSvg from '~/assets/svgs/logo.svg'

const MaintenancePage: Page = ({}) => {
  return (
    <div className="fullscreen flex items-center">
      <div className="container text-center py-40">
        <LogoSvg />
        <div className="message mt-16">
          We&apos;re making some quick updates to improve your browsing experience. <br />
          We&apos;ll be back online shortly. Thank you for your patience!
        </div>
      </div>
    </div>
  )
}

export default MaintenancePage
