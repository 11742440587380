import React from 'react'
import styles from './Circle.module.scss'
import clsx from 'clsx'

type Size = 'normal' | 'small' | 'large'

type Props = {
  color?: string
  className?: string
  size?: Size
}

export default function Circle({ color, size, className }: Props) {
  return (
    <div
      className={clsx(styles.circle, className, {
        ['bg-' + color]: color,
        ['size-' + size]: size
      })}
    />
  )
}
