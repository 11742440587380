import NavBar from '~/components/layout/NavBar'
import SideBar from '~/components/layout/SideBar'

import styles from './dashboard.module.scss'
import { useIntercom } from '~/hooks/intercom'
import { useMount } from 'react-use'
import { usePendo } from '~/hooks/pendo'

export type Props = {
  children?: JSX.Element
}

export default function DashboardLayout({ children }: Props) {
  const intercom = useIntercom()
  const pendo = usePendo()

  useMount(() => {
    intercom.mount()
    pendo.mount()
  })

  return (
    <>
      <SideBar />
      <main className={styles.main}>
        <NavBar />

        {children}
      </main>
    </>
  )
}
