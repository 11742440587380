import styles from './Loader.module.scss'
import clsx from 'clsx'

type Props = {
  className?: string
}

export default function Loader(props: Props) {
  return (
    <span className={clsx(styles.loader, props.className)}>
      <span className={styles.line}></span>
      <span className={styles.line}></span>
      <span className={styles.line}></span>
      <span className={styles.line}></span>
    </span>
  )
}
