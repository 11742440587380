import styles from './Notifications.module.scss'
import Button from '~/components/globals/Button'
import Dropdown from '~/components/globals/Dropdown'
import Spacer from '~/components/globals/Spacer'
import Pressable from '~/components/globals/Pressable'
import clsx from 'clsx'
import { useNotifications } from '~/hooks/notifications'
import type Notification from '~/models/Notification'
import { useFormatters } from '~/hooks/formatters'
import SvgIcon from '~/components/common/SvgIcon'

type Props = {
  notification: Notification
}

export function NotificationItem({ notification }: Props) {
  const { formatDateToRelative } = useFormatters()

  return (
    <Pressable to={notification.link} className="item mx-24 py-20 flex items-center">
      <div className="fs-12" dangerouslySetInnerHTML={{ __html: notification.message }}></div>
      <span className="flex-grow ml-12 color-grey-500 nowrap" color="grey-100">
        {formatDateToRelative(notification.createdAt)}
      </span>
    </Pressable>
  )
}

export default function Notifications() {
  const { hasNew, list, onScroll } = useNotifications()

  return (
    <Dropdown
      placement={'bottom-end'}
      activator={({ toggle }) => (
        <Pressable onClick={toggle} className="color-grey-500">
          <SvgIcon icon="bell-01" height={20} />
        </Pressable>
      )}
      className={clsx(styles['notifications-button'], 'ml-10', { 'has-unread': hasNew })}
    >
      <div className={styles.notifications} onScroll={(event) => onScroll(event.currentTarget)}>
        <div className={styles.header}>
          <h3>Notifications</h3>
          <Spacer />
        </div>

        <div className={styles.list}>
          {list.map((notification, i) => (
            <NotificationItem key={i} notification={notification} />
          ))}

          {/*{list.length > 0 && (*/}
          {/*  <div className="footer py-24 px-24">*/}
          {/*    <Button variant="text" textColor="grey-50 fs-12" size="sm">*/}
          {/*      <>*/}
          {/*        See all notifications*/}
          {/*        <Icon>keyboard_double_arrow_right</Icon>*/}
          {/*      </>*/}
          {/*    </Button>*/}
          {/*  </div>*/}
          {/*)}*/}

          {list.length === 0 && (
            <div className="empty py-32 px-24 flex">
              There is nothing to find here
              <Button to={`/activity-stream`} variant="text" textColor="grey-50 mt-10" size="sm">
                <>
                  See activity stream
                  <SvgIcon icon="chevron-right" />
                </>
              </Button>
            </div>
          )}
        </div>
      </div>
    </Dropdown>
  )
}
