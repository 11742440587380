import React, { useEffect } from 'react'
import ActivityStreamItem from '~/components/common/ActivityStreamItem'
import { useList } from '~/hooks/list'
import ActivityModel from '~/models/Activity'
import Timeline from '../common/Timeline'
import Button from '../globals/Button'
import Card from '../globals/Card'
import { useTenant } from '~/hooks/tenant'
import SvgIcon from '~/components/common/SvgIcon'
import clsx from 'clsx'
import type AssetProcess from '~/models/AssetProcess'

type ActivityStreamProps = {
  className?: string
  headerClassName?: string
  participantId?: string
  assetProcess?: AssetProcess
  documentId?: string
  link?: React.ReactElement
  defaultPerPage?: number
}

export default function ActivityStream({
  participantId,
  assetProcess,
  documentId,
  className,
  headerClassName,
  link,
  defaultPerPage
}: ActivityStreamProps) {
  const { tenantApi } = useTenant()

  const list = useList({
    model: ActivityModel,
    defaultSortBy: 'id',
    defaultSortDesc: true,
    autoRestoreRoute: false,
    autoUpdateRoute: false,
    offset: true,
    defaultPerPage: defaultPerPage ?? 5,
    fetch: (list) => {
      const params = {
        ...list.getQuery(true),
        participantId,
        assetProcessId: assetProcess?.id,
        documentId
      }

      return tenantApi.$get(`activity-streams`, {
        params
      })
    }
  })

  useEffect(() => {
    if (assetProcess) {
      list.scheduleReload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetProcess])

  return (
    <Card
      title="Activity stream"
      border
      className={clsx(className, { 'skeleton-container': list.isInitialLoading.value })}
      headerClassName={headerClassName}
    >
      <Timeline>
        {(list.isInitialLoading.value ? [...Array(5)] : list.rows.value).map(
          (activityModel: ActivityModel | undefined, i) => {
            return <ActivityStreamItem key={activityModel?.id ?? i} activity={activityModel} />
          }
        )}
      </Timeline>
      {!list.lastPage &&
        (link || (
          <Button variant="text" size="xs" width="full" onClick={() => list.page.value++}>
            Load more <SvgIcon icon="chevron-right" height={14} className="ml-8" />
          </Button>
        ))}
    </Card>
  )
}
