import Avatar from '~/components/globals/Avatar'
import React from 'react'
import type Participant from '~/models/Participant'
import { useTypes } from '~/hooks/types'

type Props = {
  participant: Participant
}

export function ParticipantName({ participant }: Props) {
  const { getTypeLabel } = useTypes()

  return (
    <div className="flex items-center">
      <Avatar user={participant} size={36} />

      <div className="ml-16">
        <div className="fs-15 fw-500">
          {participant.name}
          {participant.participantType === 'company' && ` (${participant.fullName})`}
        </div>
        <div className="fs-12 color-grey-500 pt-4">
          {getTypeLabel('participant', participant.participantType ?? '')}
        </div>
      </div>
    </div>
  )
}
