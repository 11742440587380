import { useMemo } from 'react'
import { APIService } from '~/services/APIService'
import config from '~/services/ConfigService'
import logger from '~/services/LoggerService'
import tenant from '~/services/TenantService'
import { useTenantStore } from '~/stores/tenant'
import { useCompanyStore } from '~/stores/company'
import TenantModel from '~/models/Tenant'
import ValuationModel from '~/models/Company/ValuationModel'
import EsopPlan from '~/models/EsopPlan'
import { useWizardStore } from '~/stores/wizard'
import CompanyShare from '~/models/CompanyShare'

export const useTenant = () => {
  const tenantStore = useTenantStore()
  const companyStore = useCompanyStore()
  const wizardStore = useWizardStore()

  const tenantUid = useMemo<string>(
    () => tenantStore.tenantUid || tenant.uid,
    [tenantStore.tenantUid]
  )

  const tenantApi = useMemo<APIService>(
    () =>
      new APIService({
        baseURL: config.API_URL + '/' + tenantUid
      }),
    [tenantUid]
  )

  const fetchCompanyShares = async () => {
    const data = await tenantApi.$get(`company-shares/sorted`)
    companyStore.setCompanyShares(data.map((s: any) => new CompanyShare(s)))
  }

  const fetchCompanyValuations = async () => {
    const res = await tenantApi.$get(`company-valuations`)
    if (Array.isArray(res.data)) {
      const valuations: ValuationModel[] = res.data.map(
        (valuation: object) => new ValuationModel(valuation)
      )
      companyStore.setValuations(valuations)
      return valuations
    }
    return []
  }

  const fetchEsopPlans = async () => {
    const result = await tenantApi.$get(`esop-plans`)
    if (Array.isArray(result)) {
      const esopPlans = result.map((esopPlan: object) => new EsopPlan(esopPlan))

      companyStore.setEsopPlans(esopPlans)
    }
  }

  const fetchOnboardingSteps = async () => {
    const res = await tenantApi.$get('tenant/onboarding')
    if (res?.steps) {
      wizardStore.setSteps(res.steps)
    }
  }

  const fetchTenantData = async () => {
    const result = await tenantApi.$get('tenant')
    const data = new TenantModel(result)
    companyStore.setTenant(data)

    logger.debug('Tenant', data)
  }

  const fetchTenant = async () => {
    try {
      await Promise.all([
        fetchTenantData(),
        fetchCompanyValuations(),
        fetchCompanyShares(),
        fetchEsopPlans(),
        fetchOnboardingSteps()
      ])
    } catch (e) {
      //
    }
  }

  return {
    tenant: companyStore.tenant,
    setTenant: companyStore.setTenant,
    changeTenant: tenantStore.setTenantUid,
    esopPlans: companyStore.esopPlans,
    tenantApi,
    tenantUid,
    fetchTenant,
    fetchCompanyShares,
    fetchCompanyValuations,
    fetchEsopPlans
  }
}

export type Tenant = ReturnType<typeof useTenant>
