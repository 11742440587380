import React from 'react'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import InnerLink from '~/components/globals/InnerLink'
import Participant from '~/models/Participant'

export default function Award({ activity }: ActivityStreamItemProps) {
  return (
    <>
      <div>
        Award details: <InnerLink withURLs={new Participant(activity.assetProcess.participant)} />,{' '}
        {activity.isCustom ? 'Custom award' : activity.assetProcess?.esopSchemaName ?? null}
      </div>
      {activity.isCustom ? <div>{`Reason: ${activity.data.note}`}</div> : null}
    </>
  )
}
