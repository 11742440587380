import { DateTime } from 'ts-luxon'
import { type WithURLs } from './BaseModel'
import Round from './Round'

export default class ExerciseRound extends Round implements WithURLs {
  static label = 'Exercise round'
  public get typeLabel() {
    return 'Exercise'
  }

  public requestedAssetCardinality!: number
  public participantDeadline!: string

  public data!: {
    pricePerAsset: number
    exerciseStart: string
    exerciseEnd: string
    assetVestingEnd: string
    companyValuationId: number
    lapseNotExercisedAssets: boolean
  }

  public get exerciseStart() {
    return DateTime.fromISO(this.data.exerciseStart).toISODate() ?? ''
  }

  public get exerciseEnd() {
    return DateTime.fromISO(this.data.exerciseEnd).toISODate() ?? ''
  }

  public get status() {
    if (this.state === 'draft') {
      return 'draft'
    }
    const currentDate = DateTime.now().toISODate() ?? ''
    return currentDate < this.exerciseStart
      ? 'pending'
      : currentDate <= this.exerciseEnd
      ? 'open'
      : 'closed'
  }

  public get urlDetail() {
    return `/exercises/${this.id}`
  }

  public get urlEdit() {
    return `/exercises/edit/${this.id}`
  }

  public get urlOverview() {
    return `/exercises`
  }
}
