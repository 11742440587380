import React from 'react'

type Props = {
  displayId: string
  desc?: string
}

export function DisplayId({ displayId, desc }: Props) {
  return (
    <div className="flex items-center">
      <div className="ml-0">
        <div className="fs-15 fw-500">{displayId}</div>
        {desc ? <div className="fs-12 color-grey-500 pt-4">{desc}</div> : null}
      </div>
    </div>
  )
}
