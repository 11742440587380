import BaseModel from '../BaseModel'
import { parseStringIntoDateTime } from '~/utils/formatters'
import { DateTime } from 'ts-luxon'

export default class ValuationPeriodModel extends BaseModel {
  public validFrom!: string
  public valuation!: number

  public get validFromDateTime() {
    return DateTime.fromISO(this.validFrom).startOf('day')
  }

  public get validFromTimestamp() {
    const date = parseStringIntoDateTime(this.validFrom)
    return this.validFrom && date ? date.toMillis() : 0
  }
}
