import clsx from 'clsx'
import styles from './ValueBox.module.scss'

interface Props {
  value: string | number
  label: string
  className?: string
  border?: boolean
}

const ValueBox = ({ value, label, className, border = true }: Props) => {
  return (
    <div className={clsx(styles.box, className, { 'has-border': border })}>
      <h1 className="value fs-39 fw-700 color-black">
        <span className="skeleton-big-number-value">{value}</span>
      </h1>
      <div className="label fs-14 color-grey-dark mt-8">
        <span className="skeleton-text">{label}</span>
      </div>
    </div>
  )
}

export default ValueBox
