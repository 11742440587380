import styles from './ProgressBar.module.scss'
import clsx from 'clsx'

type Props = {
  value: number
  endValue?: number
  className?: string
  showPercentage?: boolean
  showPercentageOnValue?: boolean
  color?: string
  bg?: string
  height?: number
}

export default function ProgressBar({
  value,
  endValue,
  className,
  showPercentage = true,
  showPercentageOnValue,
  color,
  bg,
  height = 7,
  ...props
}: Props) {
  const percent = endValue ? (value / endValue) * 100 : Math.round(value)

  const Counter = () => (
    <>
      {showPercentage ? (
        <div className="value">{percent}%</div>
      ) : endValue ? (
        <div className="end-value">
          {value}/{endValue}
        </div>
      ) : null}
      {}
    </>
  )

  return (
    <div
      className={clsx(
        styles.progressbar,
        className,
        styles[`progressbar_color_${color ? color : 'default'}`]
      )}
      {...props}
    >
      <div className="flex-grow">
        <div className={clsx('bar', { [`bg-${bg}`]: bg })} style={{ height: `${height}px` }}>
          <div className="bar-value" style={{ width: percent + '%' }}></div>
        </div>

        {showPercentageOnValue && (
          <div
            className={clsx('position-relative fs-12 color-grey mt-4', styles.value_float)}
            style={{ left: percent + '%' }}
          >
            {percent}%
          </div>
        )}
      </div>

      <Counter />
    </div>
  )
}
