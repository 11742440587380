import React from 'react'
import InnerLink from '~/components/globals/InnerLink'
import type Activity from '~/models/Activity'

type Props = {
  activity: Activity
  className?: string
}

export default function ActivityRoundLink({ activity, className }: Props) {
  let result = <>{activity.round.name}</>
  if (activity.entityModel) {
    result = <InnerLink withURLs={new activity.entityModel(activity.round)} />
  }
  if (className) {
    return <span className={className}>{result}</span>
  }
  return result
}
