import clsx from 'clsx'
import styles from './Item.module.scss'
import SvgIcon from '~/components/common/SvgIcon'
import Pressable from '~/components/globals/Pressable'
import TooltipWrap from '~/components/globals/TooltipWrap'
import { type OnboardingStepStates } from '~/hooks/wizard'

export type StepItemProps = {
  title: string
  description: string
  action?: () => void
  to?: string
  optional?: boolean
  status?: OnboardingStepStates
  tooltip?: string
}

const Item = ({ title, description, optional, status, action, to, tooltip }: StepItemProps) => {
  const classNames = [styles.item, 'flex pa-16 pb-30 relative']
  status && classNames.push('is-' + status)
  optional && classNames.push('is-' + optional)

  return (
    <div className={clsx(classNames)}>
      {status !== 'locked' && <Pressable className="over-link" to={to} onClick={action} />}
      {tooltip && status === 'locked' && (
        <TooltipWrap title={tooltip} width={150}>
          <div className="over-fill"></div>
        </TooltipWrap>
      )}

      <div>
        {status === 'enabled' ? (
          <div className="check"></div>
        ) : status === 'locked' ? (
          <div>
            <SvgIcon icon="lock-01" height={24} />
          </div>
        ) : status === 'done' ? (
          <div className="check flex items-center justify-center">
            <SvgIcon icon="check" height={16} className="color-grey-900" />
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="fs-14 flex-fill px-12">
        <div className="fw-500 mb-4 title">
          {title} {optional && <span className="optional ml-4">Optional</span>}
        </div>
        <div className="description">{description}</div>
      </div>

      <div>
        <SvgIcon icon="chevron-right" height={24} className="arrow" />
      </div>
    </div>
  )
}

export default Item
