import { useRouter } from 'next/router'
import React, { type Dispatch, type SetStateAction } from 'react'
import { DateTime } from 'ts-luxon'
import Button, { type Size, type Variant } from '~/components/globals/Button'
import Dropdown, { type IDropdownOption } from '~/components/globals/Dropdown'
import { useEligibility } from '~/hooks/eligibility'
import { useFormatters } from '~/hooks/formatters'
import { useModals } from '~/hooks/modals'
import { useTenant } from '~/hooks/tenant'
import Participant from '~/models/Participant'
import SvgIcon from '~/components/common/SvgIcon'
import { useAlerts } from '~/hooks/alerts'

type Props = {
  participant: Participant
  setParticipant?: Dispatch<SetStateAction<Participant | undefined>>
  variant?: Variant
  size?: Size
  onDelete: () => void
}

export function ParticipantActions({ participant, setParticipant, onDelete, ...props }: Props) {
  const modals = useModals()
  const router = useRouter()
  const alerts = useAlerts()
  const { tenant, tenantApi } = useTenant()
  const { checkRunningProcesses } = useEligibility()
  const { formatDate } = useFormatters()

  const onAward = () => {
    modals.open(
      import('~/modals/awards/NewAward'),
      {},
      {
        participantId: participant.id
      }
    )
  }

  const onBuyback = async () => {
    modals.open(
      import('~/modals/buybacks/NewBuyback'),
      {},
      {
        participantId: participant.id
      }
    )
  }

  const onTerminate = async () => {
    const onNothingRunning = () => {
      router.push({
        pathname: `/terminations/edit/new`,
        query: { participantId: participant.id }
      })
    }

    await checkRunningProcesses(participant, onNothingRunning)
  }

  const onEdit = () => {
    router.push(participant.urlEdit)
  }

  const onHRSync = async () => {
    tenantApi
      .$get(`participants/${participant.id}/hris-sync`)
      .then((data) => {
        setParticipant && setParticipant(new Participant(data))

        alerts.open({
          text: `${participant.name} synced successfully.`,
          type: 'success'
        })
      })
      .catch(() => {
        alerts.open({
          text: `Sync failed. Please try again later.`,
          type: 'error'
        })
      })
  }

  const deleteUser = async (participantId: number) => {
    const result = await modals.confirm({
      title: 'Delete person',
      text: 'Are you sure you want to delete this person?'
    })
    if (result) {
      tenantApi
        .$delete(`participants/${participantId}`)
        .then(() => {
          alerts.open({
            text: `User deleted successfully.`,
            type: 'success'
          })
          onDelete()
        })
        .catch((error) => {
          alerts.open({
            text: error.message,
            type: 'error'
          })
        })
    }
  }

  const options: IDropdownOption[] = [
    {
      label: 'Award',
      action: onAward,
      permissions: 'manage_processes'
    }
  ]

  if (tenant.isHrisActive && participant.hrisUid) {
    options.push(
      {
        label: 'Edit',
        tooltip:
          'Editing of participants is not allowed as long as synchronization with the HR system is active!',
        disabled: true,
        permissions: 'manage_participants'
      },
      {
        label: 'Sync with HR system',
        tooltip: `Last sync date: ${
          participant.hrisLastSyncedAt
            ? formatDate(participant.hrisLastSyncedAt, DateTime.DATETIME_MED)
            : ''
        }`,
        action: onHRSync,
        permissions: 'manage_participants'
      }
    )
  } else {
    options.push({
      label: 'Edit',
      action: onEdit,
      permissions: 'manage_participants'
    })
  }

  options.push({
    label: 'Buyback',
    action: onBuyback,
    permissions: 'manage_processes'
  })

  options.push({
    label: 'Terminate',
    action: onTerminate,
    permissions: 'manage_processes'
  })

  options.push({
    label: 'Delete',
    action: () => deleteUser(participant.id),
    permissions: 'manage_processes',
    disabled: participant.hasProcess
  })

  return (
    <Dropdown
      activator={({ toggle }) => (
        <Button variant={props.variant} size={props.size} onClick={toggle}>
          Actions
          <SvgIcon icon="chevron-down" height={16} className="ml-4" />
        </Button>
      )}
      options={options}
      placement="bottom-end"
    />
  )
}
