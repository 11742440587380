import { create } from 'zustand'
import { combine } from 'zustand/middleware'
import type User from '~/models/User'

export const useUserStore = create(
  combine(
    {
      hasHydrated: false,
      data: null as User | null,
      isLoggedIn: false
    },
    (set) => ({
      setData(data: User | null) {
        set({ data })
      },
      login(data: User) {
        this.setData(data)
        set({ isLoggedIn: true })
      },
      logout() {
        set({ isLoggedIn: false })
      }
    })
  )
)
