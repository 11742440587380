import { useEffect, useRef } from 'react'

type ReturnFn = () => void

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useMounted = (func: () => ReturnFn | void) => useEffect(func, [])

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useUnmounted = (func: () => void) => useEffect(() => func, [])

export const useWillMount = (func: () => void) => {
  const willMount = useRef(true)

  if (willMount.current) {
    func()
  }

  willMount.current = false
}
