import { type ReactElement } from 'react'

type Props = {
  children: ReactElement
}

const FooterNote = ({ children }: Props) => {
  return <div className="text-center fs-14 color-grey-900 fw-500 mt-60">{children}</div>
}

export default FooterNote
