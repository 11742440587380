import clsx from 'clsx'
import Tooltip from 'rc-tooltip'
import React from 'react'

type Props = {
  title: string | React.ReactElement
  children?: React.ReactElement
  className?: string
  trigger?: 'hover' | 'click' | string[]
  width?: number | 'auto'
  minWidth?: number
  maxWidth?: number
  placement?:
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom'
}

export default function TooltipWrap({
  title,
  className,
  maxWidth,
  minWidth,
  width = 'auto',
  trigger = 'hover',
  placement = 'top',
  children = <span>Show</span>
}: Props) {
  return (
    <Tooltip
      overlay={
        <div
          style={{ width, lineHeight: '15px', minWidth, maxWidth }}
          className={clsx('fs-12', className)}
        >
          {title}
        </div>
      }
      trigger={trigger}
      placement={placement}
    >
      {children}
    </Tooltip>
  )
}
