import { useTenant } from '~/hooks/tenant'
import { type Feature } from '~/models/Tenant'

export const useFeature = () => {
  const { tenant } = useTenant()
  const has = (feature: Feature): boolean => {
    return tenant.features?.includes(feature) ?? false
  }

  return {
    has
  }
}

export type FeatureType = ReturnType<typeof useFeature>
