import React from 'react'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import ActivityAssetProcessLink from '~/components/common/ActivityStreamItem/ActivityAssetProcessLink'
import { type ActivityAction } from '~/types/activityActions'

const PaymenInformationCheckCompleted: ActivityAction = ({ activity }: ActivityStreamItemProps) => {
  return (
    <>
      <div>Participant has confirmed their bank account details.</div>
      <div>
        Payment instructions for {activity.entityLabel} ID{' '}
        <ActivityAssetProcessLink activity={activity} /> have been generated for further processing.
      </div>
    </>
  )
}

export default PaymenInformationCheckCompleted
