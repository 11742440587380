import React from 'react'
import { DateTime } from 'ts-luxon'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import ActivityRoundLink from '~/components/common/ActivityStreamItem/ActivityRoundLink'
import { useFormatters } from '~/hooks/formatters'
import { type ActivityAction } from '~/types/activityActions'

const Published: ActivityAction = ({ activity }: ActivityStreamItemProps) => {
  const { formatDate } = useFormatters()

  return (
    <div>
      New {activity.entityLabel.toLowerCase()} for {activity.round.esopSchemaName}, round{' '}
      <ActivityRoundLink activity={activity} /> has been initiated.{' '}
      {activity.entityLabel.replace(' round', '')} window for this round ends on{' '}
      {formatDate(activity.round?.participantDeadline, DateTime.DATE_MED)}.
    </div>
  )
}

export default Published
