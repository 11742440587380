import React from 'react'
import { type ActivityStreamItemProps } from '~/components/common/ActivityStreamItem'
import ActivityAssetProcessLink from '~/components/common/ActivityStreamItem/ActivityAssetProcessLink'
import { useFormatters } from '~/hooks/formatters'
import { type ActivityAction } from '~/types/activityActions'

const VestingMilestoneAchieved: ActivityAction = ({ activity }: ActivityStreamItemProps) => {
  const { formatDate } = useFormatters()
  return (
    <div>
      Performance milestone &quot;{activity.data.vestingMilestone.performanceCriteria}&quot; was set
      to{' '}
      <span className="fw-700">
        {activity.data.deallocatedCardinality ? 'PARTIALLY ' : ''}ACHIEVED
      </span>{' '}
      for {activity.entityLabel} ID <ActivityAssetProcessLink activity={activity} />.{' '}
      {activity.data.allocatedCardinality}{' '}
      {activity.data.allocatedCardinality === 1 ? 'asset was' : 'assets were'} vested to date{' '}
      {formatDate(activity.data.vestingMilestone.vestedOnDate)}.
      {activity.data.deallocatedCardinality > 0
        ? `${activity.data.allocatedCardinality} ${
            activity.data.allocatedCardinality === 1 ? 'asset was' : 'assets were'
          } returned to the pool.`
        : ''}
    </div>
  )
}

export default VestingMilestoneAchieved
