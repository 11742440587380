let alertId = 1

type AlertType = 'error' | 'success' | 'warning' | 'info'
type ActionType = {
  label: string
  handler: () => void
}

export type AlertOptions = {
  id?: string
  type?: AlertType
  text: string | JSX.Element
  action?: ActionType
  keepOpen?: boolean
}

export default class Alert {
  public id: string
  public type: AlertType
  public text: string | JSX.Element
  public timeout = 5000
  public timer?: ReturnType<typeof setTimeout>
  public action?: ActionType | null

  constructor(options: AlertOptions) {
    this.id = options.id || String(alertId++)
    this.type = options.type || 'success'
    this.text = options.text
    this.action = options.action || null
    if (options.keepOpen) {
      this.timeout = 0
    }
  }
}
